import {Logger} from "aws-amplify";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import React from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    FormLabel,
    OverlayTrigger,
    Popover,
    Row,
} from "react-bootstrap";
import BootstrapTable, {ColumnDescription, SortOrder} from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {v4 as uuidv4} from 'uuid';
import * as yup from "yup";
import {DataAuditEvent} from "../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../CommonApi/Enum";
import {TableDefinition, TableMetadata} from "../../CommonApi/TableMetadata";
import {UsageTracking} from "../../CommonApi/UsageTracking";
import {ReactBootrapTableSorting} from "../../CommonFunc/ReactBootrapTableSorting";
import {View} from "../GenGenPlate/Enum";
import {genGenPlateApiConst} from "../GenGenPlate/GenGenPlateApiConst";
import {RowDataModal} from "../RowDataModal";
import {StringFormatter} from "../../CommonFunc/StringFormatter";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import "../GenGenPlate/RawData/GenGenPlateCalculatedResult.css"
import {TableCellFormater} from "../../CommonFunc/TableCellFormater";
import {ROW_LIMIT} from "./RowLimit";

const logger = new Logger("SearchResultTableWithUpdateUI.tsx")

interface SearchResultTableWithUpdateUIProps {
    title: string
    databaseName: string
    tableName: string
    keywords: string
    columns: string
    keyField: string
    legends: any
    rowClasses: ((row: any, rowIndex: number) => string)
    refreshToken: string
    columnsDisplayInOrder: string[]
    columnsHidden: string[]
    view: View
    enableFilter: boolean
    defaultSorted: [{dataField: any, order: SortOrder}]
    comparator: SearchBoxComparator
    updateModalFullscreen: true | string, // FIXME - Why not boolean?
    modalClassName: string
    modalForm: React.ComponentClass<any, any> // TODO after prototype - Perhaps changing it to something other than any?
    tableData: any[]
    columnsDefinitions: Record<string, string>
    columnsDescriptions: ColumnDescription[]
    dataAdmin: string,
    tableDescription: string
    searchTable: (keywords: string, columns: string, table: string, comparator: SearchBoxComparator) => Promise<any[]>
}

interface SearchResultTableWithUpdateUIState {
    dataAdmin: string
    tableDefinitionStatus: "loaded" | "loading" | "error"
    tableDefinitionErrorMessage: string
    tableDescription: string

    columnsDescriptions: ColumnDescription[]
    columnsDefinitions: Record<string, string>
    columnsMetadataStatus: "loaded" | "loading" | "error"
    columnsMetadataErrorMessage: string

    tableData: any[]
    tableDataStatus: "loading" | "error" | "loaded"
    tableDataErrorMessage: string
    formikInitialState: {
        keywords: string
        columns: string
    }
    updateFormShow: boolean
    updateFormInitialValues: any
    keywords: string
    columns: string
}

export class SearchResultTableWithUpdateUI extends React.Component<SearchResultTableWithUpdateUIProps, SearchResultTableWithUpdateUIState> {
    public static defaultProps = {
        enableFilter: true,
        defaultSorted: [{
            dataField: "lastModified",
            order: "desc"
        }],
        comparator: "like",
        updateModalFullscreen: "",
        tableData: [],
        dataAdmin: "",
        tableDescription: "",
        columnsDefinitions: {},
        columnsDescriptions: [],
        modalClassName: "",
    }

    // This is to call formik helper func outside formik, like resetForm, etc.
    private formikRef: React.RefObject<FormikProps<{ keywords: string; columns: string; }>>;

    constructor(props: SearchResultTableWithUpdateUIProps) {
        super(props);
        this.formikRef = React.createRef()
        this.state = {
            dataAdmin: "",
            tableDefinitionStatus: "loaded",
            tableDefinitionErrorMessage: "",
            tableDescription: "",
            columnsDescriptions: [{dataField: "loading", text: "loading"}],
            columnsMetadataStatus: "loaded",
            columnsMetadataErrorMessage: "",
            columnsDefinitions: {},
            tableData: [],
            tableDataStatus: "loaded",
            tableDataErrorMessage: "",
            formikInitialState: {
                columns: "",
                keywords: ""
            },
            updateFormShow: false,
            updateFormInitialValues: {},
            keywords: props.keywords,
            columns: props.columns,
        }
    }

    componentDidMount() {
        this.getColumnsDefinitions(this.props.databaseName, this.props.tableName)
        this.getTableDefinition(this.props.databaseName, this.props.tableName)
        if (this.props.keywords !== "") {
            this.applyFilter(false, this.props.databaseName, this.props.tableName, () => {})
        }
    }

    componentDidUpdate(prevProps: Readonly<SearchResultTableWithUpdateUIProps>, prevState: Readonly<SearchResultTableWithUpdateUIState>, snapshot?: any) {
        if (prevProps.refreshToken !== this.props.refreshToken) {
            logger.debug(`Refresh token changed from ${prevProps.refreshToken} to ${this.props.refreshToken}`)
            // If refresh token change, mean that the search query must come from outside the table, hence we reset the form "Search table by"
            UsageTracking.recordEvent({
                module: FrontendModule.DataCatalog,
                event: this.props.view === View.Update ? EventType.SearchBeforeUpdate : EventType.Search,
                table: this.props.tableName,
                database: this.props.databaseName,
                keywords: this.state.keywords, // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
            })
            this.setState({
                formikInitialState: {
                    columns: this.state.columnsDescriptions[0].dataField,
                    keywords: ""
                },
                keywords: this.props.keywords,
                columns: this.props.columns
            }, () => {
                this.applyFilter(false, this.props.databaseName, this.props.tableName, () => {})
            })
        }
    }

    applyFilter(searchInside: boolean, database: string, table: string, callback: () => void) {
        // If search query coming from outside this component, reset "Search table by" form to avoid confusion
        if (!searchInside) {
            // @ts-ignore
            this.formikRef.current.resetForm()
        }

        if (Object.keys(this.props.tableData).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                tableData: this.props.tableData,
                tableDataStatus: "loaded",
                tableDataErrorMessage: "",
            }))
            return
        }

        this.setState(prevState => ({
            ...prevState,
            tableDataStatus: "loading",
            updateFormShow: false,
        }), () => {
            // UsageTracking.recordEvent cannot be here, since this doesn't always trigger because people want to search,
            // it can trigger because ppl close the modal and table refresh.
            this.props.searchTable(this.state.keywords, this.state.columns, table, this.props.comparator)
                .then((response: DataAuditEvent[]) => {
                    this.setState(prevState => ({
                        ...prevState,
                        tableData: response,
                        tableDataStatus: "loaded",
                        tableDataErrorMessage: ""
                    }))
                }).catch((err: any) => {
                    logger.error(err)
                    this.setState({
                        tableDataStatus: "error",
                        tableDataErrorMessage: "Something wrong happened, please refresh the page."
                    })
                }).finally(callback)
            return
        })
    }

    // Display column definition if hover over column name
    columnHeaderFormatter(
        column: ColumnDescription<any>,
        colIndex: number,
        components: {sortElement: JSX.Element; filterElement: JSX.Element; }): any {
        const columnHeaderLabelStyle = column.dataField.toLowerCase() === "additionalnote" || column.dataField.toLowerCase() === "name" ? {width: "20rem"} : {}

        return <OverlayTrigger
            delay={{show: 250, hide: 400}}
            placement={"top"}
            overlay={
                <Popover className={"ms-2"}
                         id={`${this.props.tableName}-${column.dataField}-definition`}>
                    <Popover.Header as={"h3"}>Definition</Popover.Header>
                    <Popover.Body>
                        {this.state.columnsDefinitions[column.dataField] ? this.state.columnsDefinitions[column.dataField] : column.text}
                    </Popover.Body>
                </Popover>
            }>
            <Row>
                <Col md={10} sm={10}>
                    <label htmlFor={`${this.props.tableName}-${column.dataField}-definition`}
                           style={columnHeaderLabelStyle}>{column.text}</label>
                </Col>
                <Col md={2} sm={2}>
                    {components.sortElement}
                </Col>
            </Row>
        </OverlayTrigger>
    }

    getColumnsDefinitions(databaseName: string, tableName: string) {
        this.setState(prevState => ({
            ...prevState,
            columnsMetadataStatus: "loading"
        }), () => {
            TableMetadata.getColumnsDefinitions(
                tableName, databaseName,
                (columnsDef: Record<string, string>) => {
                    let columnsDescriptions: ColumnDescription[] = []
                    for (const columnNameFromDb in columnsDef) {
                        // Column with longs data need special treatment
                        if (columnNameFromDb.toLowerCase() === "additionalnote") {
                            columnsDescriptions.push({
                                dataField: columnNameFromDb,
                                text: StringFormatter.camelCaseToSpaceSeperatedString(columnNameFromDb),
                                hidden: this.props.columnsHidden.includes(columnNameFromDb),
                                headerFormatter: this.columnHeaderFormatter.bind(this),
                                width: 50,
                            })
                        } else {
                            // Contruct columns with sorting caret
                            columnsDescriptions.push({
                                dataField: columnNameFromDb,
                                text: StringFormatter.camelCaseToSpaceSeperatedString(columnNameFromDb),
                                hidden: this.props.columnsHidden.includes(columnNameFromDb),
                                sort: true,
                                sortCaret: ReactBootrapTableSorting.renderSortCadret,
                                sortFunc: ReactBootrapTableSorting.tableColumnSort,
                                formatter: TableCellFormater.cellFormatter,
                                // Display column definition if hover over column name
                                headerFormatter: this.columnHeaderFormatter.bind(this)
                            })
                        }
                    }
                    logger.debug("columnsDescriptions", columnsDescriptions)
                    columnsDescriptions = this.sortColumnsInOrder(columnsDescriptions, this.props.columnsDisplayInOrder)

                    logger.info("sorted for table " + tableName + " columnsDescriptions = ", columnsDescriptions)
                    this.setState(prevState => ({
                        ...prevState,
                        columnsDefinitions: columnsDef,
                        columnsDescriptions: columnsDescriptions,
                        columnsMetadataStatus: "loaded",
                        columnsMetadataErrorMessage: "",
                        formikInitialState: {
                            columns: columnsDescriptions[0].dataField,
                            keywords: ""
                        }
                    }))
                },
                (err: any) => {
                    this.setState({
                        columnsMetadataStatus: "error",
                        columnsMetadataErrorMessage: err+""
                    })
                }
            )
        })
    }

    getTableDefinition(databaseName: string, tableName: string) {
        this.setState(prevState => ({
            ...prevState,
            tableDefinitionStatus: "loading"
        }), () => {
            // Not user-initiated action, no need to UsageTracking.recordEvent
            TableMetadata.getTableDefinition(
                tableName, databaseName,
                (tableDef: TableDefinition) => {
                    this.setState(prevState => ({
                        ...prevState,
                        dataAdmin: tableDef.dataAdmin,
                        tableDescription: tableDef.description,
                        tableDefinitionStatus: "loaded",
                        tableDefinitionErrorMessage: ""
                    }))
                },
                (err: any) => {
                    this.setState({
                        tableDefinitionStatus: "error",
                        tableDefinitionErrorMessage: err + ""
                    })
                }
            )
        })
    }

    sortColumnsInOrder(columnsFromDb: ColumnDescription[], columnsOrder: string[]): ColumnDescription[] {
        let sortedColumns: ColumnDescription[] = []
        for (const columnName of columnsOrder) {
            for (const columnFromDb of columnsFromDb) {
                if (columnFromDb.dataField === columnName) {
                    sortedColumns.push(columnFromDb)
                    break;
                }
            }
        }
        logger.info("sortedColumns = ", sortedColumns)

        // Need to insert the rest.
        let unimportantColumn: ColumnDescription[] = columnsFromDb.filter(x => !sortedColumns.includes(x))
        sortedColumns.push(...unimportantColumn)

        return sortedColumns
    }

    buildColumnDescriptionList(): ColumnDescription[] {
        let columns: ColumnDescription[] = this.state.columnsDescriptions.map((elem) => elem)
        // TODO - how to decouple?
        if (this.props.view === View.Update || this.props.tableName === genGenPlateApiConst.tableName.AGGREGATED) {
            columns.unshift({
                text: "Action",
                dataField: "",
                formatter: ((cell, row, rowIndex, formatExtraData) => {
                    return <Button size={"sm"} variant={"primary"} onClick={() => {
                        // TODO - how to decouple?
                        if (this.props.tableName === genGenPlateApiConst.tableName.AGGREGATED) {
                            UsageTracking.recordEvent({
                                uuid: uuidv4(),
                                module: FrontendModule.DataCatalog,
                                event: EventType.ViewGenGenPlate,
                                table: this.props.tableName,
                                database: this.props.databaseName,
                                keywords: "",
                                timestamp: new Date()
                            })
                        }
                        this.setState({
                            updateFormShow: true,
                            // TODO: Here we are passing row values --> how can we sneek things like plateMap and rowData here?
                            updateFormInitialValues: row
                        })
                    }}>
                        {this.props.view === View.Read ? "View" : "Update"}
                    </Button>
                }),
            })
        }
        return columns
    }

    closeModal() {
        this.setState({
            updateFormShow: false
        }, () => {
            // Only refresh table if this is update view, in read view no data change, so no need to refresh table.
            if (this.props.view === View.Update) {
                this.applyFilter(false, this.props.databaseName, this.props.tableName, () => {})
            }
        })
    }

    render() {
        logger.debug("render - ", this.props.tableName, " - tableData = ", this.state.tableData)

        return <>
            {
                (this.props.view === View.Update || this.props.view === View.Read) && <RowDataModal
                    show={this.state.updateFormShow}
                    tableName={this.props.tableName}
                    initialValues={this.state.updateFormInitialValues}
                    closeModal={this.closeModal.bind(this)}
                    view={this.props.view}
                    modalFullscreen={this.props.updateModalFullscreen}
                    modalClassName={this.props.modalClassName}
                    modalForm={this.props.modalForm}
                />
            }
            {this.props.title !== "" && <h3 className={"text-primary mt-3"}>{this.props.title}</h3>}
            <Card className="bg-light mb-3">
                <Card.Header>
                    <Row>
                        <Col md={8}>
                            {this.props.enableFilter && <div className={"p-2"}>
                                <Formik
                                    // Allow calling of formik helpers outside formik
                                    innerRef={this.formikRef}
                                    enableReinitialize
                                    initialValues={this.state.formikInitialState}
                                    validationSchema={yup.object({
                                        columns: yup.string().required(),
                                        keywords: yup.string().required(),
                                    })}
                                    onSubmit={(values, {setSubmitting, resetForm}) => {
                                        setSubmitting(true)
                                        logger.debug("Submit filter - values = ", values)
                                        UsageTracking.recordEvent({
                                            module: FrontendModule.DataCatalog,
                                            event: this.props.view === View.Update ? EventType.SearchBeforeUpdate : EventType.Search,
                                            table: this.props.tableName,
                                            database: this.props.databaseName,
                                            keywords: this.state.keywords, // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
                                        })
                                        this.setState(
                                            {
                                                keywords: values["keywords"],
                                                columns: values["columns"]
                                            }, () => {
                                                this.applyFilter(true, this.props.databaseName, this.props.tableName,
                                                    () => {
                                                        setSubmitting(false)
                                                        // Do not resetForm,
                                                        // since user may want to keep search for same column
                                                        // for different keywords
                                                        // resetForm()
                                                        return
                                                    }
                                                )
                                            })
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting}) => (
                                        <Form>
                                            <Row>
                                                <Col md={5}>
                                                    <FormLabel>Find keywords:</FormLabel>
                                                    <Field
                                                        type={"text"}
                                                        name={"keywords"}
                                                        className={"form-control"}
                                                        disabled={isSubmitting}
                                                    />
                                                    <ErrorMessage
                                                        name={"keywords"}
                                                        component={"div"}
                                                        className={"field-error text-danger"}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <FormLabel>In column:</FormLabel>
                                                    <Field
                                                        name={"columns"}
                                                        as={"select"}
                                                        className={"form-control form-select"}
                                                        disabled={isSubmitting}
                                                    >
                                                        {this.state.columnsDescriptions.map((column, i) => (
                                                            <option key={this.props.tableName + "-" + i.toString()} value={column.dataField}>{StringFormatter.camelCaseToSpaceSeperatedString(column.dataField)}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                        name={"columns"}
                                                        component={"div"}
                                                        className={"field-error text-danger"}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Button type={"submit"} variant={"primary"} style={{position: "inherit", bottom: "1rem", marginTop: "2rem"}} disabled={isSubmitting}>{isSubmitting ? "Searching ..." : "Search database"}</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </div>}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {{
                        "loading": <p className={"text-primary"}>Loading column metadata ...</p>,
                        "error": <p className={"text-danger"}>{"Error loading column metadata: " + this.state.columnsMetadataErrorMessage}</p>,
                        "loaded": <></>
                    }[this.state.columnsMetadataStatus]}
                    {{
                        "loading": <p className={"text-primary"}>Loading table definition ...</p>,
                        "error": <p className={"text-danger"}>{"Error loading table definition: " + this.state.tableDefinitionErrorMessage}</p>,
                        "loaded": <></>
                    }[this.state.tableDefinitionStatus]}
                    {{
                        "loading": <p className={"text-primary"}>Loading table data ...</p>,
                        "error": <p className={"text-danger"}>{"Error loading table data: " + this.state.tableDataErrorMessage}</p>,
                        "loaded": <></>
                    }[this.state.tableDataStatus]}
                    {
                        this.state.columnsMetadataStatus === "loaded" &&
                        this.state.tableDefinitionStatus === "loaded" &&
                        this.state.tableDataStatus === "loaded" &&
                        this.state.tableData.length >= ROW_LIMIT[this.props.tableName] &&
                        <Alert variant={"warning"}>
                            <Alert.Heading>Warning!</Alert.Heading>
                            Too many results. Only display up to {ROW_LIMIT[this.props.tableName]} entries.
                        </Alert>
                    }
                    {
                        this.state.columnsMetadataStatus === "loaded" &&
                        this.state.tableDefinitionStatus === "loaded" &&
                        this.state.tableDataStatus === "loaded" &&
                        <ToolkitProvider
                            keyField={this.props.keyField}
                            data={this.state.tableData}
                            columns={this.buildColumnDescriptionList()}
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <Search.SearchBar placeholder={`Filter ${this.state.tableData.length} search results`} {...props.searchProps} />
                                            </Col>
                                        </Row>
                                        <Row className={"mt-2"}>
                                            <BootstrapTable
                                                hover
                                                bordered={true}
                                                caption={`Admin: ${this.state.dataAdmin}`}
                                                wrapperClasses={"table-responsive"}
                                                rowClasses={this.props.rowClasses}
                                                noDataIndication={<h6 className={"text-primary"}>No data! Try to search within specific column?</h6>}
                                                pagination={paginationFactory({
                                                    sizePerPageList: [10, 20, 50, 100],
                                                    hideSizePerPage: false,
                                                    showTotal: true
                                                })}
                                                bootstrap4={true}
                                                defaultSorted={this.props.defaultSorted}
                                                {...props.baseProps}
                                            />
                                        </Row>
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    }
                </Card.Body>
            </Card>
        </>;
    }
}