import {API, Logger} from "aws-amplify";
import {FormikValues} from "formik";
import {FormikHelpers} from "formik/dist/types";
import moment from "moment";
import React from "react";
import {Card} from "react-bootstrap";
import * as yup from "yup";
import {USER_EMAIL} from "../../Auth/LoginUser";
import {DataAudit} from "../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../CommonApi/Enum";
import {UsageTracking} from "../../CommonApi/UsageTracking";
import {ApiCallStatus} from "../../CommonFunc/Enum";
import {DataImportFormSubmissionStatus, HtmlForm, RowOfFormFields} from "../Common/HtmlForm";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";

const logger = new Logger("PlasmidForm.tsx")

const TABLE_NAME: string = "plasmid"

export interface Plasmid {
    id: string,
    name: string,
    guideASequence: string,
    guideBSequence: string,
    originOfInsert: string,
    antibioticSelection: string,
    owner: string,
    stockType: string
    elutedIn: string,
    stockCreationDate: string,
    backbone: string,
    location: string,
    project: string,
    additionalNote: string
}

interface PlasmidFormProps {
    displayHeader: boolean
    initialValues: Plasmid
    autofillOwnerByLogInId: boolean
    view: View
}

interface PlasmidFormState {
    rowsOfFormFields: RowOfFormFields[]
    schema: any
    initialValues: Plasmid
    submissionStatus: DataImportFormSubmissionStatus
}

export class PlasmidForm extends React.Component<PlasmidFormProps, PlasmidFormState> {
    static defaultProps = {
        displayHeader: true,
        initialValues: null,
        autofillOwnerByLogInId: true,
    }

    constructor(props: any) {
        // Constructor get call everytime we press update button from TableDataAPIWithUpdateModal
        super(props);
        let initialValuesFromProps = props.initialValues
        if (initialValuesFromProps) {
            initialValuesFromProps = {
                ...initialValuesFromProps,
                stockCreationDate: moment(new Date(initialValuesFromProps.stockCreationDate)).format("YYYY-MM-DD")
            }
        }

        this.state = {
            rowsOfFormFields: [
                {
                    colSize: 4,
                    fields: [
                        {name: "id", dataType: "text", disable: true},
                        {name: "backbone", dataType: "text", disable: false},
                        {name: "project", dataType: "text", disable: false},
                        {name: "antibioticSelection", dataType: "select", disable: false},
                        {name: "stockType", dataType: "select", disable: false},
                        {name: "elutedIn", dataType: "select", disable: false},
                        {name: "stockCreationDate", dataType: "date", disable: false},
                        {name: "originOfInsert", dataType: "text", disable: false},
                    ]
                },
                {
                    colSize: 6,
                    fields: [
                        {name: "owner", dataType: "text", disable: true},
                        {name: "location", dataType: "text", disable: false},
                        {name: "guideASequence", dataType: "text", disable: false},
                        {name: "guideBSequence", dataType: "text", disable: false},
                    ]
                },
                {
                    colSize: 12,
                    fields: [
                        {name: "additionalNote", dataType: "text", disable: false},
                    ]
                }
            ],
            schema: yup.object({
                id: yup.string().matches(/^PL[0-9]+/,
                    "Must starts with PL followed by a number. For example: PL2458").required(),
                // Regex match with string-string-string, or string --> we right now didn't check if first string match backbone name.
                guideASequence: yup.string().matches(/(^[ATGCatgc]{20}$)|(no guide a)/i,
                    "Must be either exact 20 letters of A, T, G, C or \"No guide A\"").required(),
                guideBSequence: yup.string().matches(/(^[ATGCatgc]{20}$)|(no guide b)/i,
                    "Must either has exact 20 letters of A, T, G, C or \"No guide B\"").required(),
                location: yup.string(),
                backbone: yup.string().required(),
                project: yup.string().required(),
                antibioticSelection: yup.string().required(),
                owner: yup.string().required(),
                stockType: yup.string().required(),
                elutedIn: yup.string().required(),
                stockCreationDate: yup.string(),
                originOfInsert: yup.string(),
                additionalNote: yup.string(),
            }),
            initialValues: initialValuesFromProps === null ? {
                id: "",
                name: "",
                guideASequence: "",
                guideBSequence: "",
                location: "",
                backbone: "pCombo71",
                project: "",
                antibioticSelection: "",
                owner: USER_EMAIL,
                stockType: "",
                elutedIn: "",
                stockCreationDate: "",
                originOfInsert: "",
                additionalNote: "",
            } : initialValuesFromProps,
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }
    }

    componentDidMount() {
        logger.debug("componentDidMount()")
        if (this.props.view === View.Import) {
            this.getCurrentplasmidId()
        }
    }

    getCurrentplasmidId() {
        logger.debug("getCurrentplasmidId()")
        // There is no need for UsageTracking.recordEvent
        API.get(
            gppApiConsts.API_NAME,
            gppApiConsts.plasmid.path.NEXT_ID,
            {},
        ).then(response => {
            logger.debug("Response from API", response)
            this.setState(prevState => ({
                ...prevState,
                initialValues: {
                    ...prevState.initialValues,
                    id: response["data"]
                }
            }))
        }).catch(err => {
            logger.error(err)
            this.setState(prevState => ({
                ...prevState,
                initialValues: {
                    ...prevState.initialValues,
                    id: "Error, please refresh!"
                }
            }))
        }).finally(() => {

        })
    }

    onSubmit(values: any, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
        this.setState({
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            values["guideASequence"] = values["guideASequence"].toUpperCase()
            values["guideBSequence"] = values["guideBSequence"].toUpperCase()
            // This only happened in Firefox, not in Chrome
            if (values["stockCreationDate"].toLowerCase() === "invalid date") {
                values["stockCreationDate"] = ""
            }

            logger.info("onSubmit - after uppercase conversion - values = ", values)
            formikHelpers.setSubmitting(true)

            if (this.props.view === View.Import) {
                this.apiPut(values, formikHelpers)
            } else if (this.props.view === View.Update) {
                this.apiPatch(values, formikHelpers)
            }
        })
    }

    apiPut(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Import,
            table: TABLE_NAME,
            database: gppApiConsts.DATABASE_NAME,
            keywords: "", // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.put(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                body: {
                    table: TABLE_NAME,
                    data: values,
                },
            }).then(response => {
            DataAudit.recordEvent({
                database: gppApiConsts.DATABASE_NAME,
                table: TABLE_NAME,
                rowId: values.id,
                updateNote: "",
                dataObject: values,
            })
            logger.debug("Response from API", response)
            this.setState({
                submissionStatus: {
                    status: ApiCallStatus.Completed,
                    message: values.id + " submitted successfully"
                }
            })
        }).catch(err => {
            logger.error(err)
            if (err.response) {
                if (err.response.status >= 500 || err.response.status < 400) {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: "Something wrong, please try again"
                        }
                    })
                } else {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                }
            }
        }).finally(() => {
            formikHelpers.setSubmitting(false)
            this.setState({
                initialValues: values,
            })
            this.getCurrentplasmidId()
        })
    }

    apiPatch(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Update,
            table: TABLE_NAME,
            database: gppApiConsts.DATABASE_NAME,
            keywords: values["updateNotes"], // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.patch(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                body: {
                    table: TABLE_NAME,
                    data: values,
                },
            }).then(response => {
            DataAudit.recordEvent({
                database: gppApiConsts.DATABASE_NAME,
                table: TABLE_NAME,
                rowId: values.id,
                updateNote: values["updateNotes"],
                dataObject: values,
            })
            logger.debug("Response from API", response)
            this.setState({
                submissionStatus: {
                    status: ApiCallStatus.Completed,
                    message: "Updated successfully! Record id is " + values["id"]
                }
            })
        }).catch(err => {
            logger.error(err)
            if (err.response) {
                if (err.response.status >= 500 || err.response.status < 400) {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: "Something wrong, please try again"
                        }
                    })
                } else {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                }
            }
        }).finally(() => {
            formikHelpers.setSubmitting(false)
            this.setState({
                initialValues: values,
            })
            // For PATCH, there is no need to update current plasmid ID
            // this.getCurrentplasmidId()
        })
    }

    render() {
        logger.info("state.initialvalues = ", this.state.initialValues)
        return <Card bg={"light"}>
            {this.props.displayHeader && <Card.Header><b>Add new plasmid</b></Card.Header>}
            <Card.Body>
                {/*// Wait until plasmid ID is retrieved before rendering form*/}
                {this.state.initialValues.id ? <HtmlForm
                    databaseName={gppApiConsts.DATABASE_NAME}
                    tableName={"plasmid"}
                    initialValues={this.state.initialValues}
                    schema={this.state.schema}
                    rowsOfFormFields={this.state.rowsOfFormFields}
                    onSubmit={this.onSubmit.bind(this)}
                    submissionStatus={this.state.submissionStatus}
                    autofillOwnerByLogInId={this.props.autofillOwnerByLogInId}
                    view={this.props.view}
                /> : <p className={"text-primary"}>Calculating plasmid id ...</p>}
            </Card.Body>
        </Card>
    }
}