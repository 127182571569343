import {API, Logger, Storage} from "aws-amplify";
import React from "react";
import {Alert, Button, FloatingLabel, Form} from "react-bootstrap";
import {ArrowClockwise} from "react-bootstrap-icons";
import {v4 as uuidv4} from "uuid";
import {errorMessage} from "../Alerts/Const";
import {DismissibleAlert} from "../Alerts/DismissibleAlert";
import {USER_EMAIL} from "../Auth/LoginUser";
import {EventType, FrontendModule} from "../CommonApi/Enum";
import {UsageTracking} from "../CommonApi/UsageTracking";
import {
    SYNERGY_FINDER_BUCKET_NAME,
    SynergyFinderCalculationMethod,
    synergyFinderConsts,
    SynergyFinderPipelineStatus
} from "../SynergyFinder/Const";
import {DownloadButton} from "../CommonUIComponent/DownloadButton";
import {ApiCallStatus} from "../CommonFunc/Enum";

interface SynergyFinderInputFormProps {
    refreshHistoryTable(): void
}

interface SynergyFinderInputFormState {
    description: string,
    calculationMethod: SynergyFinderCalculationMethod,
    fileUploaded: any,
    submissionStatus: ApiCallStatus
    normalization: "true" | "false"
    submissionErrorMsg: string
}

export interface BatchPipelineRequest {
    uuid: string,
    description: string,
    owner: string,
    calculationMethod: SynergyFinderCalculationMethod,
    normalization: boolean
    s3KeyInputExcel: string,
    calculationStatus: SynergyFinderPipelineStatus
    uploadTimestamp?: Date,
    errorMessage: string,
    batchJobId: string,
}

const logger = new Logger("SynergyFinderInputForm")

export class SynergyFinderInputForm extends React.Component<SynergyFinderInputFormProps, SynergyFinderInputFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            description: "",
            fileUploaded: "",
            normalization: "true",
            calculationMethod: SynergyFinderCalculationMethod.ZIP,
            submissionStatus: ApiCallStatus.NoData,
            submissionErrorMsg: "",
        }
    }

    componentDidMount() {
        logger.info("SynergyFinderInputForm - componentDidMount - current env is " + process.env.NODE_ENV)
    }

    setNormalization(e: any) {
        this.setState({
            submissionErrorMsg: "",
            normalization: e.target.value.toLowerCase()
        })
    }

    setFileUploaded(e: any) {
        this.setState({
            submissionErrorMsg: "",
            fileUploaded: e.target.files[0]
        })
    }

    setDescription(e: any) {
        this.setState({
            submissionErrorMsg: "",
            description: e.target.value.toString()
        })
    }

    setCalculationMethod(e: any) {
        this.setState({
            submissionErrorMsg: "",
            calculationMethod: e.target.value.toString()
        })
    }

    submitPipeline(e: any) {
        e.preventDefault()

        // Record events
        UsageTracking.recordEvent({
            module: FrontendModule.SynergyFinder,
            event: EventType.Import,
            table: "batch_pipeline_request",
            database: "synergyfinder",
            extraMetadata: {
                "calculationMethod": this.state.calculationMethod,
                "normalization": this.state.normalization,
                "description": this.state.description,
                "owner": USER_EMAIL,
            }
        })

        // Block the submit button, with loading animation until the form submission is completed
        this.setState({
            submissionStatus: ApiCallStatus.Loading,
            submissionErrorMsg: ""
        }, () => {
            // Check for file extension first
            let fileExtension: string = this.state.fileUploaded.name.split(".").pop()

            // Check for file type
            if (!["csv", "xlsx"].includes(fileExtension)) {
                // Notify error
                logger.error("Error: cannot accept file other than \"csv\", \"xlsx\" current format is ", fileExtension)
                this.setState({
                    submissionErrorMsg: "Only accept csv and xlsx file",
                    submissionStatus: ApiCallStatus.Error
                })
                return
            }

            // Upload file to S3 first
            let reportID: string = uuidv4()
            console.log("File uploaded: ", this.state.fileUploaded)
            console.log("File name: ", this.state.fileUploaded.name)
            let s3Key: string = reportID + "/input." + fileExtension
            Storage.put(
                s3Key,
                this.state.fileUploaded,
                {bucket: SYNERGY_FINDER_BUCKET_NAME})
                .then(response => {
                let requestBody: BatchPipelineRequest = {
                    uuid: reportID,
                    description: this.state.description,
                    owner: USER_EMAIL,
                    calculationMethod: this.state.calculationMethod,
                    normalization: this.state.normalization.toLowerCase() === "true",
                    s3KeyInputExcel: "public/" + s3Key,
                    calculationStatus: SynergyFinderPipelineStatus.IN_PROGRESS,
                    errorMessage: "",
                    batchJobId: "",
                }
                API.post(synergyFinderConsts.API_NAME, "/batch-pipeline", {body: requestBody})
                    .then(response => {
                        logger.info("Response from API - " + response)

                        // Clear form data
                        e.target["synergy-finder-submit-form-file-upload"].value = ""
                        this.setState({
                            description: "",
                            calculationMethod: SynergyFinderCalculationMethod.ZIP,
                            fileUploaded: "",
                            submissionStatus: ApiCallStatus.Completed
                        })
                    }).catch(err => {
                        // Don't clear input, notification of error
                        logger.error("Error from API " + err)
                        this.setState({
                            submissionErrorMsg: err.message,
                            submissionStatus: ApiCallStatus.Error
                        })
                    })
            }).catch(err => {
                // Don't clear input, notification of error
                logger.error(err)
                this.setState({
                    submissionStatus: ApiCallStatus.Error,
                    submissionErrorMsg: errorMessage(err)
                })
            })
        })
    }

    getEnumKeys(enumToDeconstruct: typeof SynergyFinderCalculationMethod): Array<keyof typeof enumToDeconstruct> {
        return Object.keys(enumToDeconstruct) as Array<keyof typeof enumToDeconstruct>;
    }

    render() {
        return (
            <>
                <h3>Submit a Synergy Finder job</h3>
                <Form onSubmit={this.submitPipeline.bind(this)}>
                    <Form.Group className="mb-3">
                        <FloatingLabel label="Short description">
                            <Form.Control type="text" placeholder="Short description"
                                          aria-label="Short description" aria-describedby="basic-addon1"
                                          id="synergy-finder-submit-form-description"
                                          required
                                          value={this.state.description}
                                          disabled={this.state.submissionStatus === ApiCallStatus.Loading}
                                          onChange={this.setDescription.bind(this)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel label="Calculation method">
                            <Form.Select id="synergy-finder-submit-form-calculation-method"
                                         value={this.state.calculationMethod}
                                         disabled={this.state.submissionStatus === ApiCallStatus.Loading}
                                         onChange={this.setCalculationMethod.bind(this)}
                                         required={true}
                            >
                                {
                                    this.getEnumKeys(SynergyFinderCalculationMethod).map((key, index) => <option key={index} value={SynergyFinderCalculationMethod[key]} >{key}</option>)
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel label="Normalization">
                            <Form.Select id="synergy-finder-submit-form-normalization"
                                         value={this.state.normalization}
                                         disabled={this.state.submissionStatus === ApiCallStatus.Loading}
                                         onChange={this.setNormalization.bind(this)}
                                         required={true}
                            >
                                <option key={1} value={"true"}>Yes</option>
                                <option key={2} value={"false"}>No</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Alert variant={"info"}>
                            <div>Excel need to strictly follow format - <DownloadButton buttonSize={"sm"} s3Bucket={SYNERGY_FINDER_BUCKET_NAME} s3Keys={["Example.xlsx"]} variant={"success"} text={"Download example"} /> to check</div>
                        </Alert>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Upload Excel</Form.Label>
                        <Form.Control
                            id="synergy-finder-submit-form-file-upload"
                            type="file"
                            data-show-upload="false"
                            data-show-caption="true"
                            disabled={this.state.submissionStatus === ApiCallStatus.Loading}
                            onChange={this.setFileUploaded.bind(this)}
                            required
                        />
                    </Form.Group>
                    <Button
                        id="synergy-finder-input-form-submit-button"
                        variant="primary"
                        type="submit"
                        disabled={this.state.submissionStatus === ApiCallStatus.Loading}
                    >
                        {this.state.submissionStatus === ApiCallStatus.Loading ? "Submitting ..." : "Submit"}
                    </Button>
                </Form>
                {this.state.submissionStatus === ApiCallStatus.Completed && <Alert className={"mt-3"} variant={"success"}>
                    <Alert.Heading>Success!</Alert.Heading>
                    <p>Keep refreshing <Button size={"sm"} onClick={this.props.refreshHistoryTable} variant={"primary"}><ArrowClockwise /></Button> to get up-to-date status</p>
                </Alert>}
                {this.state.submissionStatus === ApiCallStatus.Error && <DismissibleAlert className={"mt-2"} message={this.state.submissionErrorMsg} />}
            </>
        );
    }
}