export interface InputOption {
    dotmaticsPlate: string
    cellLine: string
    knockout: string
    drug: string
    target: string
    countersigned: boolean
}

export enum Normalize {
    None = "none",
    DMSO = "DMSO",
    MaxViability = "MaxViability",
}

export enum JobStatus {
    Submitted = "Submitted",
    Pending = "Pending",
    Completed = "Completed",
    Failed = "Failed",
    NoData = ""
}

export const pharmGenAggregationReportColumnDef: Record<string, string> = {
    "dotmaticsPlateId": "Dotmatics ID & Plate Number",
    "noPlates": "Number of plates with biological replicates",
    "cellLine": "Cell line name",
    "knockout": "Gene knockout",
    "drug": "Drug name",
    "drugDilution": "Drug dilution. \"mixed\" for uneven concentration distribution in log10 scale",
    "drugTarget": "Gene symbol for drug target if known",
    "ic50Um": "Absolute IC50 value with its standard error in uM",
    "ec50Um": "Relative IC50 value with its standard error in uM",
    "ec50Viability": "Viability at EC50 concentration",
    "hillSlope": "Hill slope of the fitting curve",
    "minViability": "Estimated minimum vaibility with very high drug concentration",
    "drugSensitivityScore3": "Drug Sensitivity Score 3 as a measure of a drug response. The higher the value, the better drug sensitivity. Approximate threshold ~15-20. For details, see Yadav et al., Sci Rep 4, 5193 (2014)",
    "ic20Um": "Concentration at 20% inhibition (80% viability) in uM",
    "ic80Um": "Concentration at 80% inhibition (20% viability) in uM",
    "areaUnderCurvePercentage": "Area under the fitted curve. The lower the value, the better drug sensitivity",
    "dataQualityOk": "Was the data quality for a plate ok?",
    "qcNote": "Possible reason for bad data quality",
}