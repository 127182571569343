import {Auth} from "@aws-amplify/auth";
import {API, Logger} from "aws-amplify";
import {ErrorMessage, Field, Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as React from "react";
import {Alert, Button, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {InfoCircle} from "react-bootstrap-icons";
import * as yup from "yup";
import {USER_EMAIL} from "../../Auth/LoginUser";
import {TableMetadata} from "../../CommonApi/TableMetadata";
import {View} from "../GenGenPlate/Enum";
import {genGenPlateApiConst} from "../GenGenPlate/GenGenPlateApiConst";
import {gppApiConsts} from "../GuidePlasmidIndel/GppApiConsts";
import {StringFormatter} from "../../CommonFunc/StringFormatter";
import {ApiCallStatus} from "../../CommonFunc/Enum";

import './FormCheckbox.css'

const logger = new Logger("HtmlForm.tsx")

interface HtmlFormProps {
    initialValues: any
    schema: any
    rowsOfFormFields: RowOfFormFields[]
    databaseName: string
    tableName: string
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>;
    submissionStatus: DataImportFormSubmissionStatus
    autofillOwnerByLogInId: boolean
    view: View
    temporaryDisable: boolean
    disableReason: string
    fieldsDropdownValues: Record<string, string[]>
    fieldsDefinitions: Record<string, string>
    submitButton: {
        disable: boolean
        initialText: string
        loadingText: string
    }
}

export interface DataImportFormSubmissionStatus {
    status: ApiCallStatus
    message: string
}

export interface FormField {
    name: string
    dataType: "text" | "select" | "table" | "file" | "number" | "date" | "textarea" | "custom"
    placeholder?: string
    disable: boolean
    reactElement?: JSX.Element
}

export interface RowOfFormFields {
    colSize: number
    fields: FormField[]
}

interface HtmlFormState {
    fieldsDefinitions: Record<string, string>
    fieldsDefinitionsStatus: "loaded" | "loading" | "error"
    fieldsDefinitionsErrorMessage: string

    fieldsDropdownValues: Record<string, string[]>
    fieldsDropdownValuesStatus: "loaded" | "loading" | "error"
    fieldsDropdownValuesErrorMessage: string

    rowsOfFormFields: RowOfFormFields[]

    schema: any
    initialValues: any
}

const EXTRA_ROW_OF_FORM_FIELD_FOR_UPDATE: RowOfFormFields = {
    colSize: 12,
    fields: [
        {name: "updateNote", dataType: "text", disable: false},
        {name: "user", dataType: "text", disable: true}
    ]
}

export class HtmlForm extends React.Component<HtmlFormProps, HtmlFormState> {
    static defaultProps = {
        autofillOwnerByLogInId: true,
        temporaryDisable: false,
        disableReason: "",
        fieldsDefinitions: "",
        fieldsDropdownValues: "",
        submitButton: {
            disable: false,
            initialText: "Submit",
            loadingText: "Submitting ..."
        },
        successMessage: ""
    }

    constructor(props: HtmlFormProps) {
        super(props);
        logger.debug("HtmlForm.constructor")

        // Make a shallow copy
        let rowsOfFormFields = [...props.rowsOfFormFields]
        let schema = props.schema
        let initialValues = props.initialValues

        // Unlikely this props will get update. So no need to move this logic to componentDidUpdate.
        if (props.view === View.Update) {
            if (rowsOfFormFields.length === props.rowsOfFormFields.length) {
                rowsOfFormFields.push(EXTRA_ROW_OF_FORM_FIELD_FOR_UPDATE)
            }
            // To make updateNote required field.
            schema = props.schema.concat(yup.object({
                updateNote: yup.string().required("Please summarize what you change. For example: Update guide sequence"),
                user: yup.string().required().email()
            }))
            initialValues.updateNote = ""
            initialValues.user = USER_EMAIL
        }

        let fieldsDefinitions: Record<string, string> = {}
        let fieldsDropdownValues: Record<string, string[]> = {}
        for (let row of rowsOfFormFields) {
            for (let field of row.fields) {
                fieldsDefinitions[field.name] = ""
                fieldsDropdownValues[field.name] = []
            }
        }


        this.state = {
            fieldsDefinitions: this.props.fieldsDefinitions ? this.props.fieldsDefinitions : fieldsDefinitions,
            fieldsDefinitionsStatus: this.props.fieldsDefinitions ? "loaded" : "loading",
            fieldsDefinitionsErrorMessage: "",
            fieldsDropdownValues: this.props.fieldsDropdownValues ? this.props.fieldsDropdownValues : fieldsDropdownValues,
            fieldsDropdownValuesStatus: this.props.fieldsDropdownValues ? "loaded" : "loading",
            fieldsDropdownValuesErrorMessage: "",
            initialValues: initialValues,
            rowsOfFormFields: rowsOfFormFields,
            schema: schema,
        }
    }

    componentDidUpdate(prevProps: Readonly<HtmlFormProps>, prevState: Readonly<HtmlFormState>, snapshot?: any) {
        if (this.props.initialValues.id !== this.state.initialValues.id) {
            // Originally, we use this to fix the issue where new plasmid ID passed to this.props.initialValues.id
            // but state didn't receive it, thus Formik form still have old value of plasmid ID
            this.setState({
                initialValues: this.props.initialValues
            })
        }
        if (JSON.stringify(prevProps.rowsOfFormFields) !== JSON.stringify(this.props.rowsOfFormFields)) {
            if (this.props.view === View.Update) {
                this.setState((prevState) => ({
                    ...prevState,
                    rowsOfFormFields: this.props.rowsOfFormFields.concat(EXTRA_ROW_OF_FORM_FIELD_FOR_UPDATE)
                }))
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    rowsOfFormFields: this.props.rowsOfFormFields
                }))
            }
        }
    }

    componentDidMount() {
        if (!this.props.fieldsDefinitions) {
            this.updateFormFieldsDefinitions(this.props.databaseName, this.props.tableName)
        } else {
            this.setState({
                fieldsDefinitionsStatus: "loaded",
            })
        }

        if (!this.props.fieldsDropdownValues) {
            this.updateFormFieldsDropdownValues(this.props.databaseName, this.props.tableName)
        } else {
            this.setState({
                fieldsDropdownValuesStatus: "loaded",
            })
        }

        // We need this instead of simply use USER_EMAIL because func that load USER_EMAIL from Cognito may not complete
        // At the time this component is mounted.
        if (this.props.autofillOwnerByLogInId) {
            this.autofillOwnerField()
        }
    }

    updateFormFieldsDropdownValues(databaseName: string, tableName: string) {
        // API call to get enum values of columns
        logger.debug("Database name and table name", databaseName, tableName)
        logger.debug("Start api call for " + gppApiConsts.tableMetadata.path.COLUMNS_ENUMS)
        this.setState(prevState => ({
            ...prevState,
            fieldsDropdownValuesStatus: "loading"
        }))
        // Not user-initiated --> no need recordEvent()
        // TODO - allow parent component to pass in func to load enum values, or move enum completely to data-hub, instead of using if/else here.
        if (databaseName === gppApiConsts.DATABASE_NAME) {
            API.get(
                gppApiConsts.API_NAME,
                gppApiConsts.tableMetadata.path.COLUMNS_ENUMS,
                {
                    queryStringParameters: {
                        table: tableName,
                    },
                })
                .then(response => {
                    logger.debug("Response from API", response)

                    let fieldsDropdownValues = response["data"]
                    if (this.props.view === View.Update) {
                        fieldsDropdownValues["user"] = []
                        fieldsDropdownValues["updateNote"] = []
                    }
                    // Merge this to avoid situation where there is a field in the form, but didn't exist as column in SQL table
                    // For example: field "templatePlasmid" in DatabasesImportAddNewPlasmid
                    fieldsDropdownValues = this.mergeTwoRecordsOverwriteIfNeeded(this.state.fieldsDropdownValues, fieldsDropdownValues)

                    this.setState(prevState => ({
                        ...prevState,
                        fieldsDropdownValues: fieldsDropdownValues,
                        fieldsDropdownValuesStatus: "loaded",
                        fieldsDropdownValuesErrorMessage: ""
                    }))
                }).catch(err => {
                    logger.error(err)
                    this.setState({
                        fieldsDropdownValuesStatus: "error",
                        fieldsDropdownValuesErrorMessage: err + ""
                    })
                })
        } else if (databaseName === genGenPlateApiConst.DATABASE_NAME) {
            API.get(
                genGenPlateApiConst.API_NAME,
                genGenPlateApiConst.path.TABLE_METADATA_ENUM,
                {
                    queryStringParameters: {
                        table: tableName,
                    },
                }).then(response => {
                    logger.debug("Response from API", response)
                    let fieldsDropdownValues = response["data"]
                    if (this.props.view === View.Update) {
                        fieldsDropdownValues["user"] = []
                        fieldsDropdownValues["updateNote"] = []
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        fieldsDropdownValues: fieldsDropdownValues,
                        fieldsDropdownValuesStatus: "loaded",
                        fieldsDropdownValuesErrorMessage: ""
                    }))
                }).catch(err => {
                    logger.error(err)
                    this.setState({
                        fieldsDropdownValuesStatus: "error",
                        fieldsDropdownValuesErrorMessage: err + ""
                    })
                })
        }
    }

    mergeTwoRecordsOverwriteIfNeeded(record1: Record<string, string[]>, record2: Record<string, string[]>): Record<string, string[]> {
        let mergedRecord: Record<string, string[]> = {}
        for (let key in record1) {
            mergedRecord[key] = record1[key]
        }
        for (let key in record2) {
            mergedRecord[key] = record2[key]
        }

        return mergedRecord
    }

    updateFormFieldsDefinitions(databaseName: string, tableName: string) {
        logger.debug("Database name and table name", databaseName, tableName)
        this.setState(prevState => ({
            ...prevState,
            columnsDefinitionsStatus: "loading"
        }))
        TableMetadata.getColumnsDefinitions(
            tableName, databaseName,
            (columnsDef: Record<string, string>): void => {
                if (this.props.view === View.Update) {
                    columnsDef["user"] = ""
                    columnsDef["updateNote"] = "Please summarize what changes you have made to the entry"
                }

                this.setState(prevState => ({
                    ...prevState,
                    fieldsDefinitions: columnsDef,
                    fieldsDefinitionsStatus: "loaded",
                    fieldsDefinitionsErrorMessage: ""
                }))
            },
            (err: any): void => {
                this.setState({
                    fieldsDefinitionsStatus: "error",
                    fieldsDefinitionsErrorMessage: err + ""
                })
            })

    }

    // This implies that all updatable-table should have owner column
    autofillOwnerField() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            logger.info("getUserEmail() USER_EMAIL = " + user.attributes.email)
            this.setState(prevState => ({
                ...prevState,
                initialValues: {
                    ...prevState.initialValues,
                    owner: user.attributes.email
                }
            }))
            logger.debug("getUserEmail() state = ", this.state)
        }).catch(err => {
            logger.error(err)
        });
    }

    renderField(field: FormField, isSubmitting: boolean) {
        let overlay: JSX.Element = <OverlayTrigger
            delay={{show: 250, hide: 400}}
            placement={"top"}
            overlay={
                <Popover className={"ms-2"}
                         id={`${field.name}-definition`}>
                    <Popover.Header as={"h3"}>Definition</Popover.Header>
                    <Popover.Body>{this.state.fieldsDefinitions[field.name] ? this.state.fieldsDefinitions[field.name] : "Column didn't exists in AWS Glue"}</Popover.Body>
                </Popover>
            }>
            <label htmlFor={field.name}>{StringFormatter.camelCaseToSpaceSeperatedString(field.name)} <InfoCircle /></label>
        </OverlayTrigger>
        let alert: JSX.Element = <ErrorMessage
            name={field.name}
            component={"div"}
            className={"field-error text-danger"}
        />

        if (this.state.fieldsDropdownValues[field.name] === undefined) {
            return <Alert variant={"error"}>{field.name} doesn't exist in this.state.fieldsDropdownValues</Alert>
        }

        switch (field.dataType) {
            case "text":
            case "number":
            case "date":
                return <>
                    {overlay}
                    <Field
                        type={field.dataType}
                        name={field.name}
                        placeholder={field.placeholder}
                        className={"form-control"}
                        disabled={field.disable || isSubmitting || this.props.temporaryDisable || this.props.view === View.Read}/>
                    {alert}
                </>
            case "select":
                return <>
                    {overlay}
                    <Field
                        name={field.name}
                        as={"select"}
                        className={"form-control form-select"}
                        disabled={isSubmitting || this.props.temporaryDisable || this.props.view === View.Read}
                    >
                        <option key={`${field.name}-null`} value="">Select a value</option>
                        {this.state.fieldsDropdownValues[field.name].map((val, k) =>
                            <option key={`${field.name}-${k}`} value={val}>{val}</option>
                        )}
                    </Field>
                    {alert}
                </>
            case "textarea":
                return  <>
                    {overlay}
                    <Field
                        name={field.name}
                        as={"textarea"}
                        className={"form-control"}
                        disabled={field.disable || isSubmitting || this.props.temporaryDisable || this.props.view === View.Read}
                        rows={"8"}
                    />
                    {alert}
                </>
            default:
                return <Alert variant={"danger"}>Field {field.name} has invalid dataType {field.dataType}</Alert>
        }
    }

    renderFormSubmissionStatus(): JSX.Element {
        return <Row className={"mt-2"}>
            {
                this.props.submissionStatus &&
                this.props.submissionStatus.status === ApiCallStatus.Error &&
                this.props.submissionStatus.message.toString().split("\n").map((err: string, index: number) => {
                    if (err !== "") {
                        return <Col md={12} className={"mt-2"}>
                            <Alert key={index} variant={"danger"}>{err}</Alert>
                        </Col>
                    }
                    return <></>
                })
            }
            {
                this.props.submissionStatus && this.props.submissionStatus.status === ApiCallStatus.Completed &&
                <Col md={12}>
                    <Alert variant={"success"}>{this.props.submissionStatus.message}</Alert>
                </Col>
            }
        </Row>
    }
    
    render() {
        logger.debug("HtmlForm - this.props.initialValues = ", this.props.initialValues)
        return <>
            {this.props.temporaryDisable && <Alert variant={"warning"}>
                <Alert.Heading>Warning!</Alert.Heading>
                {this.props.disableReason}
            </Alert>}
            {(this.state.fieldsDropdownValuesStatus === "error" || this.state.fieldsDefinitionsStatus === "error") &&
                <div className={"text-danger"}>Cannot load dropdown values and definitions, please refresh the page or re-login.</div>}
            {(this.state.fieldsDropdownValuesStatus === "loading" || this.state.fieldsDefinitionsStatus === "loading") &&
                <div className={"text-primary"}>Loading dropdown values and definitions ...</div>}
            {
                this.state.fieldsDropdownValuesStatus === "loaded" &&
                this.state.fieldsDefinitionsStatus === "loaded" &&
                <Formik
                    enableReinitialize
                    initialValues={this.state.initialValues}
                    validationSchema={this.state.schema}
                    onSubmit={this.props.onSubmit}
                >
                    {
                        ({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          status
                        }) => {
                            return <Form>
                                {this.state.rowsOfFormFields.map((row, i) => (
                                    <Row key={i}>
                                        {row.fields.map((field, j) => {
                                            return (
                                                <Col md={row.colSize} className={"mt-2"} key={`${i}-${j}`}>
                                                    {this.renderField(field, isSubmitting)}
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                ))}
                                {this.renderFormSubmissionStatus()}
                                {!this.props.submitButton.disable && <Row className={"mt-2"}>
                                    <Col md={12} style={{display: this.props.view === View.Read ? "none" : "block"}}>
                                        <Button
                                            variant={"primary"}
                                            type={"submit"} disabled={isSubmitting || this.props.temporaryDisable}
                                            className={"float-end"}
                                        >
                                            {isSubmitting ? this.props.submitButton.loadingText : this.props.submitButton.initialText}
                                        </Button>
                                    </Col>
                                </Row>}
                            </Form>
                        }
                    }
                </Formik>
            }
        </>;
    }
}