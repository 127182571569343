import React from "react";
import {Badge} from "react-bootstrap";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "../Common/SearchResultTableWithUpdateUI";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";
import {GppTableDataApi} from "./GppTableDataApi";

interface GuideSearchResultTableState {}

interface GuideSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    legends: boolean
}

export class GuideSearchResultTable extends React.Component<GuideSearchResultTableProps, GuideSearchResultTableState> {
    static defaultProps = {
        legends: true
    }

    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={gppApiConsts.DATABASE_NAME}
            tableName={"guide"}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            view={View.Read}
            keyField={"id"}
            title={"Guide"}
            modalForm={React.Component} // Empty component class since this never need modal
            legends={<p><Badge bg={"danger"} className={"me-2"}> </Badge>Num of targeting genes <b>OR</b> num of locations within gene &gt; 1</p>}
            rowClasses = {(row, index) => {
                if (row.numberOfTargetingGenes > 1 || row.numberOfLocationsWithinGene > 1) {
                    return "table-danger"
                }
                return "table-light"
            }}
            columnsHidden={[
                "pam", "chromosome", "restrictionEnzymeSites", "mm3", "mm4", "mm5", "sequencePolyA",
                "sequencePolyT", "sequencePolyG", "sequencePolyC", "numberOfScreens",
                "lastModified"
            ]}
            columnsDisplayInOrder={[
                "guideSequence", "engineId", "gene", "engineScore", "mismatch", "mm0", "mm1", "mm2",
                "externalLibrary", "numberOfTargetingGenes", "numberOfLocationsWithinGene", "pamMotif",
                "sequenceGCContent", "usedInScreen", "offTargetScore", "onTargetScore", "id"]}
            searchTable={GppTableDataApi.searchTable}
        />
    }
}