import React from "react";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "../Common/SearchResultTableWithUpdateUI";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";
import {GppTableDataApi} from "./GppTableDataApi";
import {PlasmidForm} from "./PlasmidForm";

interface PlasmidSearchResultTableState {}

interface PlasmidSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    view: View
    enableFilter: boolean
}

export class PlasmidSearchResultTable extends React.Component<PlasmidSearchResultTableProps, PlasmidSearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
        enableFilter: true,
    }

    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={gppApiConsts.DATABASE_NAME}
            tableName={"plasmid"}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            keyField={"id"}
            title={this.props.view === View.Update ? "" : "Plasmid"}
            modalForm={PlasmidForm}
            legends={<></>}
            rowClasses={row => "table-light"}
            columnsHidden={[]}
            columnsDisplayInOrder={[
                "owner", "id", "name", "guideASequence", "guideBSequence", "backbone", "antibioticSelection", "elutedIn",
                "originOfInsert", "project", "stockType", "stockCreationDate", "lastModified", "additionalNote"
            ]}
            view={this.props.view}
            enableFilter={this.props.enableFilter}
            searchTable={GppTableDataApi.searchTable}
        />
    }
}