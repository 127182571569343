import React from "react";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "../Common/SearchResultTableWithUpdateUI";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";
import {GppTableDataApi} from "./GppTableDataApi";
import {WesternBlotForm} from "./WesternBlotForm";

interface WesternBlotSearchResultTableState {}

interface WesternBlotSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    view: View
    enableFilter: boolean
}

export class WesternBlotSearchResultTable extends React.Component<WesternBlotSearchResultTableProps, WesternBlotSearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
        enableFilter: true,
    }

    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={gppApiConsts.DATABASE_NAME}
            tableName={"western_blot"}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            keyField={"id"}
            legends={<></>}
            title={this.props.view === View.Update ?  "" : "Western Blot"}
            modalForm={WesternBlotForm}
            rowClasses={() => ""}
            columnsHidden={[]}
            columnsDisplayInOrder={[
                "owner", "plasmidId", "plateId", "cellLine", "gene", "quantificationScore", "guideSequence", "guideDescription", "id"
            ]}
            view={this.props.view}
            enableFilter={this.props.enableFilter}
            searchTable={GppTableDataApi.searchTable}
        />
    }
}