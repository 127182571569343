import {PharmGenAggregation} from "../PharmGenAggregation/PharmGenAggregation";

export enum FrontendModule {
    DataCatalog = "DataCatalog",
    SangerQc = "SangerQc",
    SynergyFinder = "SynergyFinder",
    PharmGenAggregation = "PharmGenAggregation"
}

export enum EventType {
    ViewGenGenPlate = "ViewGenGenPlate",
    DownloadFileFromS3 = "DownloadFileFromS3",
    SearchInternalApi = "SearchInternalApi",
    Search = "Search",
    SearchBeforeUpdate = "SearchBeforeUpdate",
    Update = "Update",
    Import = "Import",
    SelectedToView = "SelectedToView",
    DeselectedFromView = "DeselectedFromView"
}