import {API, Logger} from "aws-amplify";
import {FormikHelpers, FormikValues} from "formik";
import React from "react";
import {Alert, Card, Col, Row, Table} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import * as yup from "yup";
import {USER_EMAIL} from "../../../Auth/LoginUser";
import {ApiCallStatus} from "../../../CommonFunc/Enum";
import {DataImportFormSubmissionStatus, HtmlForm} from "../../Common/HtmlForm";
import {GenGenPlateAggregatedGetResponse} from "../Aggregated/GenGenPlateAggregatedSearchResultTable";
import {View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {GenGenPlateMapPlasmid} from "./GenGenPlateMapPlasmid";

const logger = new Logger("GenGenPlateMapForm.tsx")

export interface GenGenPlateMap {
    plateId: string
    plateMap: string
    owner?: string
}

interface GenGenPlateMapFormProps {
    displayHeader: boolean
    initialValues: GenGenPlateMap // initialValues is what passed to component when you click on row in table data.
    autofillOwnerByLogInId: boolean
    view: View
}

interface GenGenPlateMapFormState {
    // plateId need to be in both state and props,
    owner: string
    initialValues: GenGenPlateMap
    geneAs: string
    geneBs: string
    getGeneAsGeneBsStatus: DataImportFormSubmissionStatus // This we can't avoid, since at this component we have to decide whether to render plasmid or rnpTracr layout
    genGenPlateMapRefreshToken: string
}


export class GenGenPlateMapForm extends React.Component<GenGenPlateMapFormProps, GenGenPlateMapFormState> {
    static defaultProps = {
        displayHeader: true,
        initialValues: null,
        autofillOwnerByLogInId: true,
    }

    constructor(props: GenGenPlateMapFormProps) {
        super(props);
        this.state = {
            owner: "",
            geneAs: "",
            geneBs: "",
            initialValues: props.initialValues === null ? {
                plateId: "",
                plateMap: "",
                owner: "",
            } : this.props.initialValues,
            getGeneAsGeneBsStatus: {
                status: ApiCallStatus.NoData,
                message: ""
            },
            genGenPlateMapRefreshToken: uuidv4()
        }
    }

    componentDidMount() {
        if (this.props.view === View.Update) {
            this.getGeneAsGeneBsFromPlateId(this.props.initialValues)
        }
    }

    componentDidUpdate(prevProps: Readonly<GenGenPlateMapFormProps>, prevState: Readonly<GenGenPlateMapFormState>, snapshot?: any) {
        if ((this.props.view === View.Update || this.props.view === View.Import) &&
            this.props.initialValues && prevProps.initialValues &&
            this.props.initialValues.plateId !== prevProps.initialValues.plateId) {
            this.getGeneAsGeneBsFromPlateId(this.props.initialValues)
        }
    }

    getGeneAsGeneBsFromPlateId(values: any, formikHelpers?: FormikHelpers<FormikValues>): void | Promise<any> {
        this.setState({
            getGeneAsGeneBsStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            formikHelpers?.setSubmitting(true)
            API.post(
                genGenPlateApiConst.API_NAME,
                genGenPlateApiConst.path.AGGREGATED,
                {
                    body: {
                        keywords: "",
                        plateId: values.plateId,
                    },
                }).then(response => {
                    logger.info("Response from gen gen aggregated API", response)
                    let data: GenGenPlateAggregatedGetResponse = response["data"][0]
                    if (this.props.view === View.Import) {
                        if (data.plateMap && data.plateMap.plateMap !== "") {
                            this.setState({
                                getGeneAsGeneBsStatus: {
                                    status: ApiCallStatus.Error,
                                    message: `Plate map for ${values.plateId} already exists`
                                }
                            })
                        } else {
                            this.setState({
                                owner: data.metadata.owner,
                                initialValues: {
                                    plateId: data.metadata.plateId,
                                    plateMap: "",
                                    owner: ""
                                },
                                geneAs: data.metadata.geneAs,
                                geneBs: data.metadata.geneBs,
                                getGeneAsGeneBsStatus: {
                                    status: ApiCallStatus.Completed,
                                    message: `Found plate ${values.plateId} and now populate plasmid dropdown for plate map`
                                }
                            })
                        }
                    } else if (this.props.view === View.Update) {
                        if (!data.plateMap) {
                            this.setState({
                                getGeneAsGeneBsStatus: {
                                    status: ApiCallStatus.Error,
                                    message: `No plate map exists for ${values.plateId}, import it first.`
                                }
                            })
                        } else {
                            this.setState({
                                owner: data.metadata.owner,
                                initialValues: data.plateMap,
                                geneAs: data.metadata.geneAs,
                                geneBs: data.metadata.geneBs,
                                getGeneAsGeneBsStatus: {
                                    status: ApiCallStatus.Completed,
                                    message: `Found plate ${values.plateId} and now populate plasmid dropdown for plate map`
                                }
                            })
                        }
                    }
                }).catch(err => {
                    logger.error(err)
                    this.setState({
                        getGeneAsGeneBsStatus: {
                            status: ApiCallStatus.Error,
                            message: err + ""
                        }
                    })
                }).finally(() => {
                    formikHelpers?.setSubmitting(false)
                    this.setState({
                        genGenPlateMapRefreshToken: uuidv4()
                    })
                })
                return;
        })
    }

    render() {
        let plateMap = <GenGenPlateMapPlasmid
            owner={this.state.owner}
            genGenPlateMap={this.state.initialValues}
            geneAs={this.state.geneAs}
            geneBs={this.state.geneBs}
            view={this.props.view}
            refreshToken={this.state.genGenPlateMapRefreshToken}
        />

        if (this.props.view === View.Read) {
            return <>{plateMap}</>
        }

        return <Card bg={"light"}>
            {this.props.displayHeader && <Card.Header><b>Add new gen-gen plate map</b></Card.Header>}
            <Card.Body>
                {
                    this.props.view === View.Import &&
                    <>
                        <HtmlForm
                            databaseName={genGenPlateApiConst.DATABASE_NAME}
                            tableName={genGenPlateApiConst.tableName.MAP}
                            initialValues={this.state.initialValues}
                            rowsOfFormFields={[
                                {
                                    colSize: 12,
                                    fields: [
                                        {name: "plateId", dataType: "text", disable: false, placeholder: "c402ffbc-1c2f-4112-acd9-eca6fd873d60"},
                                    ],
                                },
                            ]}
                            schema={yup.object({
                                plateId: yup.string().required(),
                            })}
                            onSubmit={this.getGeneAsGeneBsFromPlateId.bind(this)}
                            submissionStatus={this.state.getGeneAsGeneBsStatus}
                            autofillOwnerByLogInId={true}
                            view={this.props.view}
                            submitButton={{
                                disable: false,
                                initialText: "Generate plate layout",
                                loadingText: "Checking gene A gene B ..."
                            }}
                            fieldsDefinitions={{
                                "plateId": "You can find plate ID via search UI, or from the green success message right after you upload plate metadata",
                            }}
                            fieldsDropdownValues={{
                                "plateId": [],
                            }}
                        />
                    </>
                }
                {
                    // To fix double warning, remove this.props.view === View.Update since RowUpdateModal would take care of that in Update view
                    (this.props.view === View.Import) &&
                    this.state.getGeneAsGeneBsStatus.status === ApiCallStatus.Completed &&
                    this.state.owner !== "" &&
                    this.state.owner !== USER_EMAIL &&
                    <Alert variant={"warning"} className={"mt-2"}>
                        <Alert.Heading>Warning!</Alert.Heading>
                        <p>You are submitting plate map belong to {this.state.owner}, please confirm with them before proceeding.</p>
                    </Alert>
                }
                {
                    (this.props.view === View.Update || this.props.view === View.Import) &&
                    this.state.getGeneAsGeneBsStatus.status === ApiCallStatus.Completed &&
                    <Row className={"mt-2"}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>
                                            Owner
                                        </th>
                                        <th>
                                            Gene A
                                        </th>
                                        <th>
                                            Gene B
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.owner}</td>
                                        <td>{this.state.geneAs}</td>
                                        <td>{this.state.geneBs}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
                {
                    (this.props.view === View.Update || this.props.view === View.Import) &&
                    this.state.getGeneAsGeneBsStatus.status === ApiCallStatus.Completed &&
                    plateMap
                }
            </Card.Body>
        </Card>
    }
}