import {API, Logger} from "aws-amplify";
import React from "react";
import {Alert, Badge, Col, Row, Spinner} from "react-bootstrap";
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";
import {ApiCallStatus} from "../../../CommonFunc/Enum";
import {CollectionRound, View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import "./GenGenPlateCalculatedResult.css"
import {GenGenPlateCalculatedResultChart} from "./GenGenPlateCalculatedResultChart";
import {CurrentPng} from "recharts-to-png";

const logger = new Logger("GenGenPlateCalculatedResult")

class CalculatedResult {
    table: CalculatedResultTable = {columns: [], data: null}
    chart: any = null
}

interface CalculatedResultTable {
    columns: ColumnDescription[]
    data: any
}

interface GenGenPlateCalculatedResultProps {
    refreshToken: string
    plateId: string
    view: View
    collectionRound: CollectionRound
}

interface GenGenPlateCalculatedResultState {
    plateId: string
    calculatedResultStatus: ApiCallStatus
    calculatedResultMessage: ""
    initialValues: CalculatedResult
}

export class GenGenPlateCalculatedResult extends React.Component<GenGenPlateCalculatedResultProps, GenGenPlateCalculatedResultState> {
    static defaultProps = {
        plateId: -1
    }

    constructor(props: GenGenPlateCalculatedResultProps) {
        super(props);
        this.state = {
            plateId: "",
            calculatedResultStatus: ApiCallStatus.NoData,
            calculatedResultMessage: "",
            initialValues: {
                table: {
                    columns: [],
                    data: null
                },
                chart: null
            },
        }
    }

    componentDidMount() {
        if (this.props.collectionRound !== CollectionRound.Final) {
            return
        }

        if (this.props.plateId !== "") {
            this.setState({
                plateId: this.props.plateId
            }, this.getCalculationResult)
        }
    }

    componentDidUpdate(prevProps: Readonly<GenGenPlateCalculatedResultProps>, prevState: Readonly<GenGenPlateCalculatedResultState>, snapshot?: any) {
        if (this.props.collectionRound !== CollectionRound.Final) {
            return
        }

        if (prevProps.plateId !== this.props.plateId || prevProps.refreshToken !== this.props.refreshToken) {
            this.setState({
                plateId: this.props.plateId
            }, this.getCalculationResult)
        }
    }

    blissFvCellClasses(cell: string, row: any, rowIndex: number, colIndex: number): string {
        console.log("GenGenPlateAggregatedSearchResultTable.blissFvCellFormatter row = ", row)
        console.log("GenGenPlateAggregatedSearchResultTable.blissFvCellFormatter colIndex = ", colIndex)
        // Color green if SL
        if (cell === "SL") {
            return "bg-success text-white"
        }

        // Color less green if 0.2 <= FV < 0.5
        if (cell === "SS") {
            return "bg-less-success text-dark"
        }

        return ""
    }

    getCalculationResult() {
        this.setState({
            calculatedResultStatus: ApiCallStatus.Loading,
            initialValues: {
                table: {
                    columns: [],
                    data: []
                },
                chart: null,
            },
        }, () => {
            API.get(
                genGenPlateApiConst.API_NAME,
                `${genGenPlateApiConst.path.CALCULATION}/${this.props.plateId}`,
                {})
                .then(response => {
                    logger.debug("Response from calculation API", response)
                    // needs to inject cellFormatter here to initialValues.table.columns
                    let calculatedResult: CalculatedResult = response["data"]
                    for (let i = 0; i < calculatedResult.table.columns.length; i++) {
                        calculatedResult.table.columns[i].classes = this.blissFvCellClasses
                    }

                    this.setState({
                        initialValues: calculatedResult,
                        calculatedResultStatus: ApiCallStatus.Completed,
                        calculatedResultMessage: "",
                    })
                }).catch(err => {
                    logger.error(err)
                    this.setState({
                        calculatedResultStatus: ApiCallStatus.Error,
                        calculatedResultMessage: err.toString()
                    })
                })
        })
    }

    setPlateId(plateId: string) {
        this.setState({
            plateId: plateId
        }, this.getCalculationResult)
    }

    render() {
        if (this.state.calculatedResultStatus === ApiCallStatus.Error) {
            return <Alert variant={"danger"}>{this.state.calculatedResultMessage}</Alert>
        }

        if (this.props.collectionRound !== CollectionRound.Final) {
            return <Alert variant={"info"}>Only display calculation for final collection round</Alert>
        }

        if (this.state.calculatedResultStatus === ApiCallStatus.Loading) {
            return <Row className={"mt-3"}>
                <Col md={12}>
                    <Alert variant={"info"}><Spinner animation="border" variant="primary" size={"sm"}/> Calculating result from final collection raw data ...</Alert>
                </Col>
            </Row>
        }

        if (this.state.calculatedResultStatus === ApiCallStatus.Completed) {
            return <>
                <CurrentPng>
                    {
                        (props) => <GenGenPlateCalculatedResultChart chartData={this.state.initialValues.chart} {...props}  />
                    }
                </CurrentPng>
                <Row className={"mt-2"} style={{overflowX: "scroll"}}>
                    <Col md={12}>
                        <BootstrapTable
                            hover
                            striped
                            bordered={true}
                            keyField={"0"}
                            classes={"small-table"}
                            columns={this.state.initialValues.table.columns}
                            data={this.state.initialValues.table.data}
                            rowClasses={() => ""}
                            noDataIndication={<h6 className={"text-primary"}>No data found!</h6>}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Badge bg={"success"} className={"me-2"}>SL (BLISS &gt; 0.1 & FV &lt; 0.2)</Badge>
                        <Badge className={"bg-less-success me-2 text-dark"}>SS (BLISS &gt; 0.1 & 0.2 &lt; FV &lt; 0.5)</Badge>
                    </Col>
                </Row>
            </>
        }

        return <></>
    }
}