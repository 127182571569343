import {API, Logger} from "aws-amplify";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import {GenGenPlateAggregatedGetResponse} from "./Aggregated/GenGenPlateAggregatedSearchResultTable";
import {genGenPlateApiConst} from "./GenGenPlateApiConst";
import {GenGenPlateMetaData} from "./Metadata/GenGenPlateMetadataForm";
import {GenGenPlateMap} from "./PlateMap/GenGenPlateMapForm";
import {GenGenPlateRawData} from "./RawData/GenGenPlateRawDataForm";

const logger = new Logger("GenGenPlateTableDataApi")

export interface GenGenPlateGetAggregatedResponse {
    metadata: GenGenPlateMetaData
    plateMap: GenGenPlateMap
    rawData: GenGenPlateRawData[]
}

export class GenGenPlateTableDataApi {
    static searchTable(keywords: string, columns: string, table: string, comparator: SearchBoxComparator): Promise<GenGenPlateRawData[] | GenGenPlateMap[] | GenGenPlateAggregatedGetResponse[] | GenGenPlateMetaData[]> {
        return API.post(
            genGenPlateApiConst.API_NAME,
            genGenPlateApiConst.path.AGGREGATED,
            {
                body: {
                    keywords: keywords.trim(),
                    columns: columns,
                },
            }).then(response => {
                response = response["data"]
                switch (table) {
                    case genGenPlateApiConst.tableName.AGGREGATED:
                        return GenGenPlateTableDataApi.convertGetAggregatedResponseToPlateMetadata(response)
                    case genGenPlateApiConst.tableName.PLATE_METADATA:
                        logger.info("Response extracted from aggregated api response, ", GenGenPlateTableDataApi.convertGetAggregatedResponseToPlateMetadata(response))
                        return GenGenPlateTableDataApi.convertGetAggregatedResponseToPlateMetadata(response)
                    case genGenPlateApiConst.tableName.MAP:
                        return GenGenPlateTableDataApi.convertGetAggregatedResponseToPlateMap(response)
                    case genGenPlateApiConst.tableName.RAW_DATA:
                        return GenGenPlateTableDataApi.convertGetAggregatedResponseToRawData(response)
                    default:
                        return response
                }
            })
    }

    static convertGetAggregatedResponseToPlateMetadata(data: GenGenPlateGetAggregatedResponse[]): GenGenPlateMetaData[] {
        let result: GenGenPlateMetaData[] = []
        for (const aggregated of data) {
            if (aggregated.metadata) {
                result.push(aggregated.metadata)
            }
        }
        return result
    }

    static convertGetAggregatedResponseToPlateMap(data: GenGenPlateGetAggregatedResponse[]): GenGenPlateMap[] {
        let result: GenGenPlateMap[] = []
        for (const aggregated of data) {
            if (aggregated.plateMap) {
                result.push(aggregated.plateMap)
            }
        }
        logger.info("Result plate map converted: ", result)
        return result
    }

    static convertGetAggregatedResponseToRawData(data: GenGenPlateGetAggregatedResponse[]): GenGenPlateRawData[] {
        let result: GenGenPlateRawData[] = []
        for (const aggregated of data) {
            if (aggregated.rawData) {
                for (const rawData of aggregated.rawData) {
                    result.push(rawData)
                }
            }
        }
        return result
    }
}