import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "../CommonCss/Container.css"
import {PharmGenAggregationInputForm} from "./PharmGenAggregationInputForm"
import {PharmGenAggregationReport} from "./PharmGenAggregationReport"
import {ApiCallStatus} from "../CommonFunc/Enum";
import {AggregatedVisualizationPutApiRequest, AggregatedVisualizationPutApiResponse, convertToMap} from "./ApiModels";
import {JobStatus} from "./Models";
import {API, Logger} from "aws-amplify";
import {pharmGenAggregationApiConsts} from "./Const";
import {UsageTracking} from "../CommonApi/UsageTracking";
import {v4 as uuidv4} from "uuid";
import {EventType, FrontendModule} from "../CommonApi/Enum";
import {shortenEnv} from "../../Const";

const logger = new Logger("PharmGenAggregation.tsx")

interface PharmGenAggregationProps {}

interface PharmGenAggregationState {
    submitApiCallStatus: ApiCallStatus
    submitErrorMessage: string
    submitResponse: AggregatedVisualizationPutApiResponse
    singleCurvePerPlot: boolean
}

export class PharmGenAggregation extends React.Component<PharmGenAggregationProps, PharmGenAggregationState> {
    constructor(props: any) {
        super(props);
        this.state = {
            singleCurvePerPlot: true,
            submitApiCallStatus: ApiCallStatus.NoData,
            submitErrorMessage: "",
            submitResponse: {
                message: "",
                status: JobStatus.NoData,
                data: []
            }
        }
    }

    setSubmittedParams(id: string, singleCurvePerPlot: boolean) {

    }

    componentDidMount() {

    }

    submitAllChoicesWithRetry(selectedValues: AggregatedVisualizationPutApiRequest) {
        this.setState({
            submitApiCallStatus: ApiCallStatus.Loading,
            submitErrorMessage: ""
        }, async () => {
            UsageTracking.recordEvent({
                uuid: uuidv4(),
                module: FrontendModule.PharmGenAggregation,
                event: EventType.Search,
                table: "pharmgen-aggregation-data",
                database: `${shortenEnv}-data-hub`,
                timestamp: new Date(),
                keywords: selectedValues.knockouts.concat(selectedValues.drugs, selectedValues.targets, selectedValues.dotmaticsPlates).join(","),
                extraMetadata: convertToMap(selectedValues)
            })
            try {
                let response: AggregatedVisualizationPutApiResponse = await this.put(selectedValues)
                this.setState(
                    {
                        submitApiCallStatus: ApiCallStatus.Completed,
                        submitErrorMessage: "",
                        submitResponse: response,
                        singleCurvePerPlot: selectedValues.singleCurvePerPlot,
                    })
            } catch (e: any) {
                let errorMsg: string = "" + e
                if (e.response && e.response.message) {
                    errorMsg = e.response.message
                } else if (e.response && e.response.data) {
                    errorMsg = e.response.data.message
                }

                this.setState({
                    submitApiCallStatus: ApiCallStatus.Error,
                    submitErrorMessage: errorMsg === "Error: Network Error" ? "Server took too long to response, please narrow your query" : errorMsg,
                    submitResponse: {},
                    singleCurvePerPlot: selectedValues.singleCurvePerPlot,
                })
            }
        })
    }

    async put(req: AggregatedVisualizationPutApiRequest): Promise<AggregatedVisualizationPutApiResponse> {
        try {
            let response = await API.put(
                pharmGenAggregationApiConsts.API_NAME,
                pharmGenAggregationApiConsts.API_PATH.AGGREGATED_VISUALIZATION,
                {
                    body: req,
                })
            logger.info("put response = ", response)
            return response
        } catch (e: any) {
            throw e
        }
    }

    render() {
        return (
            <>
                <h3>Drug - Cell Line Viability</h3>
                <Container className="p-4 bg-light wide-container">
                    <Row>
                        <Col md={"12"}>
                            <PharmGenAggregationInputForm
                                submit={this.submitAllChoicesWithRetry.bind(this)}
                                submitApiCallStatus={this.state.submitApiCallStatus}
                                submitErrorMessage={this.state.submitErrorMessage}
                            />
                        </Col>
                    </Row>
                    {
                        (this.state.submitApiCallStatus === ApiCallStatus.Loading || this.state.submitApiCallStatus === ApiCallStatus.Completed)
                        && <hr/>
                    }
                    <Row>
                        <Col md={"12"}>
                            <PharmGenAggregationReport
                                status={this.state.submitApiCallStatus}
                                result={this.state.submitResponse.data ? this.state.submitResponse.data : []}
                                singleCurvePerPlot={this.state.singleCurvePerPlot}
                            />
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}