import React from "react";
import {AggregatedVisualizationResultRow} from "./ApiModels";
import {Badge, Col, Image, OverlayTrigger, Popover, Row, Spinner, Table} from "react-bootstrap";
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import ToolkitProvider, {CSVExport} from "react-bootstrap-table2-toolkit";
import {BUCKET_NAME} from "./Const";
import {Logger, Storage} from "aws-amplify";
import {ReactBootrapTableSorting} from "../CommonFunc/ReactBootrapTableSorting";
import {ApiCallStatus} from "../CommonFunc/Enum";
import {CheckCircleFill, CloudDownload, CursorText, Dot, PlusCircle, XCircleFill} from "react-bootstrap-icons";
import {TableColumnFormatter} from "../CommonFunc/TableColumnFormatter";
import {pharmGenAggregationReportColumnDef} from "./Models";

const { ExportCSVButton } = CSVExport;

interface PharmGenAggregationReportProps {
    result: AggregatedVisualizationResultRow[]
    singleCurvePerPlot: boolean
    status: ApiCallStatus
}

interface PharmGenAggregationReportState {
    columnDescription: ColumnDescription[]
    selectedRows: AggregatedVisualizationResultRow[]
    multipleCurvesPerPlotImage: string
}

const logger = new Logger("PharmGenAggregationReport")

export class PharmGenAggregationReport extends React.Component<PharmGenAggregationReportProps, PharmGenAggregationReportState> {
    constructor(props: PharmGenAggregationReportProps) {
        super(props);
        this.state = {
            selectedRows: [],
            columnDescription: [
                {
                    dataField: "id",
                    text: "",
                    hidden: true,
                },
                {
                    dataField: "jobId",
                    text: "",
                    hidden: true,
                },
                {
                    dataField: "dotmaticsPlateId",
                    text: "Dotmatics Plate ID",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
                {
                    dataField: "noPlates",
                    text: "No. Of Plates",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "dataQualityOk",
                    text: "Data Quality OK?",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    formatter: ((cell: boolean, row: AggregatedVisualizationResultRow, rowIndex, formatExtraData) => {
                        if (cell === false) {
                            return <XCircleFill color={"#dc3545"}/>
                        }
                        return <CheckCircleFill color={"#198754"}/>
                    }),
                },
                {
                    dataField: "qcNote",
                    text: "Data Quality Issue",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
                {
                    dataField: "cellLine",
                    text: "Cell Line",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
                {
                    dataField: "knockout",
                    text: "Knockout",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
                {
                    dataField: "drug",
                    text: "Drug",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
                {
                    dataField: "drugTarget",
                    text: "Drug Target",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
                {
                    dataField: "ic50Um",
                    text: "IC50 (uM)",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "ec50Um",
                    text: "EC50 (uM)",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "ec50Viability",
                    text: "EC50 Viability Percentage",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "hillSlope",
                    text: "Hill Slope",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "minViability",
                    text: "Min Viability",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "drugSensitivityScore3",
                    text: "Drug Sensitivity Score 3",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "ic20Um",
                    text: "IC20 (uM)",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "ic80Um",
                    text: "IC80 (uM)",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "areaUnderCurvePercentage",
                    text: "Area Under Curve (%)",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                },
                {
                    dataField: "drugDilution",
                    text: "Drug Dilution",
                    sort: true,
                    sortCaret: ReactBootrapTableSorting.renderSortCadret,
                    sortFunc: ReactBootrapTableSorting.tableColumnSort,
                    headerFormatter: this.columnHeaderFormatter.bind(this),
                    filter: textFilter({
                        placeholder: "Filter",
                        className: TableColumnFormatter.renderCssClassForFilter(19),
                    }),
                },
            ],
            multipleCurvesPerPlotImage: ""
        }
    }

    resetSelectedRow() {
        this.setState({
            selectedRows: []
        })
    }

    async addOrRemoveSelectedRows(clickedRows: AggregatedVisualizationResultRow[], isSelect: boolean) {
        let result: AggregatedVisualizationResultRow[] = JSON.parse(JSON.stringify(this.state.selectedRows))
        let rowsExist: string[] = []
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            for (let clickedRow of clickedRows) {
                if (this.state.selectedRows[i].id === clickedRow.id) {
                    rowsExist.push(clickedRow.id)
                    if (!isSelect) {
                        let resultWithClickedRowRemoved: AggregatedVisualizationResultRow[] = []
                        for (let j = 0; j < result.length; j++) {
                            if (result[j].id !== clickedRow.id) {
                                resultWithClickedRowRemoved.push(result[j])
                            }
                        }
                        result = resultWithClickedRowRemoved
                    }
                }
            }
        }

        // If the row is selected, instead of deselected
        if (isSelect) {
            for (let clickedRow of clickedRows) {
                if (!(clickedRow.id in rowsExist)) {
                    let clickedRowWithDownloadUrl: AggregatedVisualizationResultRow = await this.getDownloadImageUrl(clickedRow)
                    result.push(clickedRowWithDownloadUrl)
                }
            }
        }

        logger.info("final result after merged rows = ", result)
        this.setState({
            selectedRows: result
        })
        return
    }

    // Save download image url in a new object of JobResultRow
    async getDownloadImageUrl(selectedRow: AggregatedVisualizationResultRow): Promise<AggregatedVisualizationResultRow> {
        if (!selectedRow.s3Key || selectedRow.s3Key === "" || selectedRow.s3Key === "NA") {
            logger.error("s3Key is empty in selectedRow = ", selectedRow)
            selectedRow.s3GeneratedDownloadUrl = ""
            return selectedRow
        }

        try {
            let downloadURL: string = await Storage.get(selectedRow.s3Key.replace("public/", ""), {
                bucket: BUCKET_NAME,
                download: false,
            })
            selectedRow.s3GeneratedDownloadUrl = downloadURL
        } catch (e: any) {
            selectedRow.s3GeneratedDownloadUrl = "" + e;
        }

        return selectedRow
    }

    columnHeaderFormatter(column: ColumnDescription<any>, colIndex: number, components: {sortElement: JSX.Element; filterElement: JSX.Element; }): any {
        let popoverId: string = `${column.dataField}-definition`
        logger.info("Entering columnHeaderFormatter")

        return <OverlayTrigger
            delay={{show: 250, hide: 400}}
            placement={"top"}
            overlay={
                <Popover className={"ms-2"}
                         id={popoverId}>
                    <Popover.Header as={"h3"}>Definition</Popover.Header>
                    <Popover.Body>
                        {pharmGenAggregationReportColumnDef[column.dataField]}
                    </Popover.Body>
                </Popover>
            }
        >
            <Row>
                <Col md={12} sm={12}><label htmlFor={popoverId} className={"link-primary"}>{column.text}</label></Col>
                <Col md={12}><span className={"text-end"}>{components.sortElement}</span></Col>
                <Col md={12}>{components.filterElement}</Col>
            </Row>
        </OverlayTrigger>
    }

    componentDidUpdate(prevProps: Readonly<PharmGenAggregationReportProps>, prevState: Readonly<PharmGenAggregationReportState>, snapshot?: any) {
        if (this.props.status === ApiCallStatus.Error || this.props.status === ApiCallStatus.NoData) {
            return
        }

        // First, reset selected rows if API response changes
        if (JSON.stringify(this.props.result) !== JSON.stringify(prevProps.result) ||
            this.props.singleCurvePerPlot !== prevProps.singleCurvePerPlot) {
            if (!this.props.singleCurvePerPlot) {
                // If user select multiple curve per plot - download img of the first item in props.result
                this.getDownloadImageUrl(this.props.result[0]).then(response => this.setState({
                    multipleCurvesPerPlotImage: response.s3GeneratedDownloadUrl ? response.s3GeneratedDownloadUrl : ""
                }))
            } else {
                this.setState({
                    multipleCurvesPerPlotImage: ""
                })
            }
            this.resetSelectedRow()
        }
    }

    render() {
        if (this.props.status === ApiCallStatus.NoData || this.props.status === ApiCallStatus.Error) {
            return <></>
        }

        let selectedRowWithImg = this.state.selectedRows
            .filter(val => val.s3GeneratedDownloadUrl?.startsWith("https://"))

        return <>
            <Row>
                <Col md={12}>
                    {
                        this.props.status === ApiCallStatus.Loading &&
                        <>
                            <Spinner
                                animation="border"
                                size="sm"
                                variant="primary"
                            >
                            </Spinner>
                            <span className="text-primary m-2 fw-bold">Up to 2 mins, narrow your search if it took too long ...</span>
                        </>
                    }
                    {
                        this.props.status === ApiCallStatus.Completed &&
                        <>
                            <h4>Result</h4>
                            <ToolkitProvider
                                keyField={"id"}
                                data={this.props.result}
                                columns={this.state.columnDescription}
                                search
                            >
                                {
                                    // TODO - add sorting and exporting
                                    props => (
                                        <>
                                            <Row>
                                                <Col md={6}>
                                                    <p>Scroll down to see <Badge bg={"success"}>{selectedRowWithImg.length}</Badge> selected graphs</p>
                                                </Col>
                                                <Col md={6}>
                                                    <ExportCSVButton className={"btn-success btn-sm float-end"} {...props.csvProps}><CloudDownload/></ExportCSVButton>
                                                </Col>
                                            </Row>
                                            <BootstrapTable
                                                hover
                                                bordered={true}
                                                rowClasses = {""}
                                                selectRow={this.props.singleCurvePerPlot ? {
                                                    mode: 'checkbox',
                                                    clickToSelect: true,
                                                    onSelect: (row , isSelected, rowIndex, e) => {
                                                        this.addOrRemoveSelectedRows([row], isSelected)
                                                    },
                                                    onSelectAll: (isSelect, rows) => {
                                                        this.addOrRemoveSelectedRows(rows, isSelect)
                                                    }
                                                } : undefined}
                                                filter={filterFactory()}
                                                wrapperClasses={"table-responsive table-small-medium-text bg-light"}
                                                noDataIndication={<h6 className={"text-primary"}>No data found!</h6>}
                                                bootstrap4={true}
                                                {...props.baseProps}
                                            />
                                        </>
                                    )
                                }
                            </ToolkitProvider>
                        </>
                    }
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <Row>
                        {
                            this.props.status === ApiCallStatus.Completed &&
                            (!this.props.singleCurvePerPlot) &&
                            <Col md={12}>
                                <Image src={this.state.multipleCurvesPerPlotImage} width={"100%"} />
                            </Col>
                        }
                        {
                            this.props.status === ApiCallStatus.Completed &&
                            this.props.singleCurvePerPlot &&
                            selectedRowWithImg.map(selectedRow =>
                                {
                                    return <Col
                                        className={"mt-2"}
                                        key={selectedRow.id}
                                        md={selectedRowWithImg.length >= 3 ? 6 : undefined}>
                                        <Image src={selectedRow.s3GeneratedDownloadUrl} width={"100%"}/>
                                    </Col>
                                }
                            )
                        }
                    </Row>
                </Col>
                {
                    this.props.status === ApiCallStatus.Completed &&
                    (selectedRowWithImg.length > 0 || !this.props.singleCurvePerPlot)
                    &&
                    <Col md={3}>
                        <h6 className={"text-primary mt-4 fw-bold"}>Legends</h6>
                        <Table striped bordered hover>
                            <tbody>
                            <tr>
                                <td><PlusCircle color="rgb(58, 58, 58)" size={30}/></td>
                                <td>DMSO</td>
                            </tr>
                            <tr>
                                <td><hr style={{height:"1px", borderTop: "3px solid rgb(58, 58, 58)", opacity: "1"}} /></td>
                                <td>Fitted curve</td>
                            </tr>
                            <tr>
                                <td><div style={{width: "4rem", height: "1.5rem", background: "rgb(58, 58, 58)", opacity: 0.1}}></div></td>
                                <td>95% confidence interval area</td>
                            </tr>
                            <tr>
                                <td><hr style={{height:"1px", borderTop: "3px dashed rgb(58, 58, 58)", opacity: "1"}} /></td>
                                <td colSpan={2}>
                                    <p>Vertical: EC50 (uM)/IC50 (uM)</p>
                                    <p>Horizontal: EC50 (uM)/IC50 (uM) viability</p>
                                </td>
                            </tr>
                            <tr>
                                <td><CursorText color={"rgb(58, 58, 58)"} size={30}/><Dot style={{marginLeft: "-1.875rem"}} size={30} /></td>
                                <td>Error bars for Standard Deviation (N=1) or Standard Error (N&gt;1)</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                }
            </Row>
        </>
    }
}