import React from "react";
import Select, {ActionMeta, MultiValue, SingleValue} from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

interface MyOption {
    label: string;
    value: any;
}

//define the group option type
interface GroupedOption {
    label: string; // group label
    options: MyOption[];
}

// component props
interface ReactSelectProps extends Omit<StateManagerProps<MyOption, false | true, GroupedOption>, "value" | "onChange"> {
    name: string;
    passSelectedValuesAfterUserDoneSelecting: (name: string, value: any) => void
    value: any;
    placeholder: string
    customStyle: any
}

interface ReactSelectState {}

export class FormikReactSelect extends React.Component<ReactSelectProps, ReactSelectState> {
    static defaultProps = {
        placeholder: "Select ...",
        customStyle: {}
    }

    reactSelectOnChangeUpdateFormikField(value: MultiValue<MyOption> | SingleValue<MyOption>, actionMeta: ActionMeta<MyOption>): void {
        //here I used explicit typing but there maybe a better way to type the value.
        const _val = value as MyOption[] | MyOption;
        const isArray = Array.isArray(_val);
        if (isArray) {
            const values = _val.map((o) => o.value);
            // TODO - check why we need this.props.name here?
            this.props.passSelectedValuesAfterUserDoneSelecting(this.props.name, values);
        } else {
            this.props.passSelectedValuesAfterUserDoneSelecting(this.props.name, _val.value);
        }
    }

    render() {
        const flattenedOptions = this.props.options?.flatMap((o) => {
            const isNotGrouped = "value" in o;
            if (isNotGrouped) {
                return o;
            } else {
                return o.options;
            }
        });
        // TODO - we want to remove Formik from MultiSelect, but then what is this.props.value for? and what is this value"
        const value: MyOption[] | undefined = flattenedOptions?.filter((o) => {
            const isArrayValue = Array.isArray(this.props.value);
            if (isArrayValue) {
                const values = this.props.value as Array<any>;
                return values.includes(o.value);
            } else {
                return this.props.value === o.value;
            }
        })

        return (
            <Select
                {...this.props}
                className={"form-select-lg p-0"}
                value={value}
                onChange={this.reactSelectOnChangeUpdateFormikField.bind(this)}
                placeholder={this.props.placeholder}
                styles={this.props.customStyle}
            />
        );
    }
}
