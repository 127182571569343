import React from "react";
import {DataAudit, DataAuditEvent} from "../../CommonApi/DataAudit";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "../GuidePlasmidIndel/GppApiConsts";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "./SearchResultTableWithUpdateUI";

interface HistorySearchResultTableState {}

interface HistorySearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
}

export class HistorySearchResultTable extends React.Component<HistorySearchResultTableProps, HistorySearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
    }

    searchTable(keywords: string, columns: string, table: string, comparator: SearchBoxComparator): Promise<DataAuditEvent[]> {
        return DataAudit.searchEvent(
            keywords.trim(),
            columns.replace(/\s/g,''))
    }

    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={gppApiConsts.DATABASE_NAME}
            tableName={"update_history"}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            view={View.Read}
            modalForm={React.Component} // Empty class component since this table never need modal.
            keyField={"id"}
            title={"History"}
            legends={<></>}
            rowClasses={(row, index) => "table-light"}
            columnsHidden={["id", "sqlQuery"]}
            columnsDisplayInOrder={["table", "rowId", "user", "updateNote", "lastModified"]}
            searchTable={this.searchTable.bind(this)}
        />
    }
}