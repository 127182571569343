import React from "react";
import {API, Logger} from "aws-amplify";
import {InputOption, Normalize} from "./Models";
import {MultiSelect} from "./MultiSelect";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {ApiCallStatus} from "../CommonFunc/Enum";
import {AggregatedVisualizationPutApiRequest} from "./ApiModels";
import Form from 'react-bootstrap/Form';
import {v4 as uuidv4} from 'uuid';
import {pharmGenAggregationApiConsts} from "./Const";

interface PharmGenAggregationInputFormProps {
    submit(selectedValues: AggregatedVisualizationPutApiRequest): void
    submitApiCallStatus: ApiCallStatus
    submitErrorMessage: string
}

interface PharmGenAggregationInputFormState {
    refreshId: string
    allOptions: InputOption[]
    allOptionsApiCallStatus: ApiCallStatus
    allOptionsErrorMessage: string
    possibleOptionsBasedOnSelectedValues: {
        dotmaticsPlate: string[],
        cellLine: string[],
        knockout: string[],
        drug: string[],
        target: string[],
    }
    selectedValues: AggregatedVisualizationPutApiRequest
}

const logger = new Logger("PharmGenAggregationInputForm.tsx")

export class PharmGenAggregationInputForm extends React.Component<PharmGenAggregationInputFormProps, PharmGenAggregationInputFormState> {
    constructor(props: PharmGenAggregationInputFormProps) {
        super(props);
        this.state = {
            refreshId: uuidv4(),
            allOptions: [],
            allOptionsApiCallStatus: ApiCallStatus.NoData,
            allOptionsErrorMessage: "",
            possibleOptionsBasedOnSelectedValues: {
                dotmaticsPlate: [],
                cellLine: [],
                knockout: [],
                drug: [],
                target: [],
            },
            selectedValues: {
                dotmaticsPlates: [],
                cellLines: [],
                knockouts: [],
                drugs: [],
                targets: [],
                normalizeDMSO: Normalize.MaxViability,
                singleCurvePerPlot: true,
                summarizeBiologicalReplicates: true,
                onlyCountersignedPlate: false,
                confidenceInterval: true,
            },
        }
    }

    onAfterSelectDotmaticsPlate(inputOptions: string[]) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                dotmaticsPlates: inputOptions
            }
        }), () => this.narrowDownPossibleOptions("dotmaticsPlate"))
    }

    onAfterSelectCellLine(inputOptions: string[]) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                cellLines: inputOptions
            }
        }), () => this.narrowDownPossibleOptions("cellLine"))
    }

    onAfterSelectGene(inputOptions: string[]) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                knockouts: inputOptions
            }
        }), () => this.narrowDownPossibleOptions("knockout"))
    }

    onAfterSelectDrug(inputOptions: string[]) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                drugs: inputOptions
            }
        }), () => this.narrowDownPossibleOptions("drug"))
    }

    onAfterSelectTarget(inputOptions: string[]) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                targets: inputOptions
            }
        }), () => this.narrowDownPossibleOptions("target"))
    }

    onAfterSelectOnlyCountersignedPlate(obj: any) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                onlyCountersignedPlate: obj.target.checked,
            }
        }), () => this.narrowDownPossibleOptions("countersigned"))
    }

    narrowDownPossibleOptions(ignoreField: keyof InputOption) {
        // Loop through this.state.selectedChoices, list out all InputOptions that match all.
        let possibleOptionsBasedOnSelectedValues: InputOption[] = []
        for (let option of this.state.allOptions) {
            // TODO - ignoreField
            if (this.state.selectedValues.knockouts.length > 0 && !this.state.selectedValues.knockouts.includes(option.knockout)) {
                continue
            }
            if (this.state.selectedValues.drugs.length > 0 && !this.state.selectedValues.drugs.includes(option.drug)) {
                continue
            }
            if (this.state.selectedValues.targets.length > 0 && !this.state.selectedValues.targets.includes(option.target)) {
                continue
            }
            if (this.state.selectedValues.cellLines.length > 0 && !this.state.selectedValues.cellLines.includes(option.cellLine)) {
                continue
            }
            if (this.state.selectedValues.dotmaticsPlates.length > 0 && !this.state.selectedValues.dotmaticsPlates.includes(option.dotmaticsPlate)) {
                continue
            }
            possibleOptionsBasedOnSelectedValues.push(option)
        }

        let inputOption: InputOption = {
            dotmaticsPlate: "",
            cellLine: "",
            target: "",
            drug: "",
            knockout: "",
            countersigned: false
        }
        let possibleOptions = {}
        Object.keys(inputOption).forEach(key => {
            if (key !== ignoreField) {
                logger.info("possibleOptionsBasedOnSelectedValues = ", possibleOptionsBasedOnSelectedValues.map(val => val[key as keyof InputOption]))
                // @ts-ignore
                possibleOptions[key] = possibleOptionsBasedOnSelectedValues
                    .filter(val => {
                        // Filter based on countersigned
                        if (this.state.selectedValues.onlyCountersignedPlate) {
                            return val.countersigned
                        }
                        return true
                    })
                    .map(val => val[key as keyof InputOption])
                    .filter((val, i, arr) => arr.indexOf(val) === i)
                    .sort()
            }
        })

        this.setState(prevState => ({
            ...prevState,
            possibleOptionsBasedOnSelectedValues: {
                ...prevState.possibleOptionsBasedOnSelectedValues,
                ...possibleOptions,
            }
        }))
    }

    getAllOptions() {
        this.setState({
            allOptions: [],
            allOptionsApiCallStatus: ApiCallStatus.Loading,
            allOptionsErrorMessage: "",
            possibleOptionsBasedOnSelectedValues: {
                dotmaticsPlate: ["Loading ..."],
                cellLine: ["Loading ..."],
                knockout: ["Loading ..."],
                drug: ["Loading ..."],
                target: ["Loading ..."],
            }
        }, async () => {
            let response = await API.get(
                pharmGenAggregationApiConsts.API_NAME,
                pharmGenAggregationApiConsts.API_PATH.MENU_OPTIONS,
                {},
            )
            let optionsRetrieved: InputOption[] = response.data
            logger.info("response = ", response)
            this.setState({
                allOptions: optionsRetrieved,
                possibleOptionsBasedOnSelectedValues: {
                    dotmaticsPlate: optionsRetrieved.map(val => val.dotmaticsPlate)
                        .filter((val, index, arr) => arr.indexOf(val) === index)
                        .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                        .sort(),
                    cellLine: optionsRetrieved.map(val => val.cellLine)
                        .filter((val, index, arr) => arr.indexOf(val) === index)
                        .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                        .sort(),
                    knockout: optionsRetrieved.map(val => val.knockout)
                        .filter((val, index, arr) => arr.indexOf(val) === index)
                        .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                        .sort(),
                    drug: optionsRetrieved.map(val => val.drug)
                        .filter((val, index, arr) => arr.indexOf(val) === index)
                        .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                        .sort(),
                    target: optionsRetrieved.map(val => val.target)
                        .filter((val, index, arr) => arr.indexOf(val) === index)
                        .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                        .sort(),
                }, // This only work if getAllOptions get call only once during component mounting.
                allOptionsApiCallStatus: ApiCallStatus.Completed,
                allOptionsErrorMessage: ""
            })
        })
    }

    setNormalize(obj: any) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                normalizeDMSO: obj.target.value,
            }
        }))

        if (obj.target.value === Normalize.None) {
            this.setState(prevState => ({
                ...prevState,
                selectedValues: {
                    ...prevState.selectedValues,
                    summarizeBiologicalReplicates: false,
                }
            }))
        }
    }

    setSingleCurvePerPlot(obj: any) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                singleCurvePerPlot: !obj.target.checked,
            }
        }))
    }

    setSummarizeBiologicalReplicates(obj: any) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                summarizeBiologicalReplicates: obj.target.checked,
            }
        }))
    }

    setConfidenceInterval(obj: any) {
        this.setState(prevState => ({
            ...prevState,
            selectedValues: {
                ...prevState.selectedValues,
                confidenceInterval: obj.target.checked,
            }
        }))
    }

    resetAllChoices(): void {
        this.setState({
            refreshId: uuidv4(),
            possibleOptionsBasedOnSelectedValues: {
                dotmaticsPlate: this.state.allOptions.map(val => val.dotmaticsPlate)
                    .filter((val, i, arr) => arr.indexOf(val) === i)
                    .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                    .sort(),
                cellLine: this.state.allOptions.map(val => val.cellLine)
                    .filter((val, i, arr) => arr.indexOf(val) === i)
                    .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                    .sort(),
                knockout: this.state.allOptions.map(val => val.knockout)
                    .filter((val, i, arr) => arr.indexOf(val) === i)
                    .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                    .sort(),
                drug: this.state.allOptions.map(val => val.drug)
                    .filter((val, i, arr) => arr.indexOf(val) === i)
                    .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                    .sort(),
                target: this.state.allOptions.map(val => val.target)
                    .filter((val, i, arr) => arr.indexOf(val) === i)
                    .filter((val, i, arr) => val !== null && val !== undefined && val.trim() !== "")
                    .sort(),
            },
            selectedValues: {
                dotmaticsPlates: [],
                cellLines: [],
                knockouts: [],
                drugs: [],
                targets: [],
                normalizeDMSO: Normalize.MaxViability,
                singleCurvePerPlot: true,
                summarizeBiologicalReplicates: true,
                confidenceInterval: true,
                onlyCountersignedPlate: false,
            },
        })
    }

    componentDidMount() {
        this.getAllOptions()
    }

    render() {
        return <>
            <Row>
                <Col md={3}>
                    <Form.Label>Cell line</Form.Label>
                    <MultiSelect
                        refreshId={this.state.refreshId}
                        title={"Select cell lines ..."}
                        inputOptionKeyValues={"cellLine"}
                        allInputOptions={this.state.possibleOptionsBasedOnSelectedValues.cellLine}
                        passSelectedInputOptions={this.onAfterSelectCellLine.bind(this)} />
                </Col>
                <Col md={3}>
                    <Form.Label>Knockout</Form.Label>
                    <MultiSelect
                        refreshId={this.state.refreshId}
                        title={"Select knockout ..."}
                        inputOptionKeyValues={"knockout"}
                        allInputOptions={this.state.possibleOptionsBasedOnSelectedValues.knockout}
                        passSelectedInputOptions={this.onAfterSelectGene.bind(this)} />
                </Col>
                <Col md={3}>
                    <Form.Label>Drug</Form.Label>
                    <MultiSelect
                        refreshId={this.state.refreshId}
                        title={"Select drug ..."}
                        inputOptionKeyValues={"drug"}
                        allInputOptions={this.state.possibleOptionsBasedOnSelectedValues.drug}
                        passSelectedInputOptions={this.onAfterSelectDrug.bind(this)} />
                </Col>
                <Col md={3}>
                    <Form.Label>Target</Form.Label>
                    <MultiSelect
                        refreshId={this.state.refreshId}
                        title={"Select target ..."}
                        inputOptionKeyValues={"target"}
                        allInputOptions={this.state.possibleOptionsBasedOnSelectedValues.target}
                        passSelectedInputOptions={this.onAfterSelectTarget.bind(this)} />
                </Col>
            </Row>
            <Row className={"mt-4"}>
                <Col md={3}>
                    <Form.Label>Plate ID</Form.Label>
                    <MultiSelect
                        refreshId={this.state.refreshId}
                        title={"Select Dotmatics Experiment ID and Plate Number ..."}
                        inputOptionKeyValues={"dotmaticsPlate"}
                        allInputOptions={this.state.possibleOptionsBasedOnSelectedValues.dotmaticsPlate}
                        passSelectedInputOptions={this.onAfterSelectDotmaticsPlate.bind(this)} />
                </Col>
                <Col md={9}>
                    <Row>
                        <Col md={2}>
                            <Form.Label>Normalize</Form.Label>
                            <Form.Select
                                name={"Normalize"}
                                as={"select"}
                                className={"form-control form-select"}
                                onChange={this.setNormalize.bind(this)}
                                value={this.state.selectedValues.normalizeDMSO}
                            >
                                <option key={"normalize-max-viability"} value={"MaxViability"}>Max Viability</option>
                                <option key={"normalize-dmso"} value={"DMSO"}>DMSO</option>
                                <option key={"normalize-none"} value={"none"}>None</option>
                            </Form.Select>
                        </Col>
                        <Col md={2}>
                            <Form.Label>Merge to one plot</Form.Label>
                            <Form.Check
                                name={"singleCurvePerPlot"}
                                id={"singleCurvePerPlot"}
                                onClick={this.setSingleCurvePerPlot.bind(this)}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Label>Summarize biological replicates</Form.Label>
                            <Form.Check
                                name={"setSummarizeBiologicalReplicates"}
                                id={"setSummarizeBiologicalReplicates"}
                                defaultChecked={true}
                                onClick={this.setSummarizeBiologicalReplicates.bind(this)}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>95% Confidence Interval</Form.Label>
                                <Form.Check
                                    name={"confidenceInterval"}
                                    id={"confidenceInterval"}
                                    defaultChecked={true}
                                    onClick={this.setConfidenceInterval.bind(this)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Only countersigned plate</Form.Label>
                                <Form.Check
                                    name={"onlyCountersignedPlate"}
                                    id={"onlyCountersignedPlate"}
                                    onClick={this.onAfterSelectOnlyCountersignedPlate.bind(this)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {this.props.submitApiCallStatus === ApiCallStatus.Error &&
                <Row className={"mt-2"}>
                    <Col md={12}>
                        <Alert variant={"danger"}>{this.props.submitErrorMessage}</Alert>
                    </Col>
                </Row>
            }
            <div className={"mt-4"}>
                <Button className={"float-end ms-2"} variant={"success"} onClick={() => this.props.submit(this.state.selectedValues)} disabled={this.props.submitApiCallStatus === ApiCallStatus.Loading}>
                    {this.props.submitApiCallStatus === ApiCallStatus.Loading ? "Submitting ..." : "Submit"}
                </Button>
                <Button className={"float-end"} variant={"warning"} onClick={this.resetAllChoices.bind(this)} disabled={this.props.submitApiCallStatus === ApiCallStatus.Loading}>
                    Reset all choices
                </Button>
            </div>
        </>;
    }
}