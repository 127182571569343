import React from "react";
import {FormikReactSelect} from "../CommonUIComponent/FormikReactSelect";
import {InputOption} from "./Models";
import {ApiCallStatus} from "../CommonFunc/Enum";
import * as yup from "yup";
import {Logger} from "aws-amplify";

const logger = new Logger("MultiSelect.tsx")

interface MultiSelectProps {
    title: string
    refreshId: string
    inputOptionKeyValues: string
    passSelectedInputOptions: (inputOptions: string[]) => void
    allInputOptions?: string[]
}

interface MultiSelectState {
    initVal: any
    schema: any

    allOptions: string[]
    allOptionsApiCallStatus: ApiCallStatus
    allOptionsErrorMessage: string

    selectedOptions: string[]
    selectedOptionsApiCallStatus: ApiCallStatus
    selectedOptionsErrorMessage: string
}

export class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {
    constructor(props: MultiSelectProps) {
        super(props);
        this.state = {
            initVal: {
                options: ["loading"]
            },
            schema: yup.object({
                options: yup.array().of(yup.string()).required()
            }),
            allOptions: [],
            allOptionsApiCallStatus: ApiCallStatus.NoData,
            allOptionsErrorMessage: "",
            selectedOptions: [],
            selectedOptionsApiCallStatus: ApiCallStatus.NoData,
            selectedOptionsErrorMessage: "",
        }
    }

    isItemUnique(arr: InputOption[], item: InputOption, index: number): boolean {
        let duplicated = false
        for (let i = 0; i < index; i++) {
            if (item[this.props.inputOptionKeyValues as keyof InputOption] === arr[i][this.props.inputOptionKeyValues as keyof InputOption]) {
                duplicated = true
                break;
            }
        }
        return !duplicated
    }

    setAllUniqueOptions() {
        if (this.props.allInputOptions && this.props.allInputOptions.length > 0) {
            this.setState({
                allOptions: this.props.allInputOptions
            })
        }
    }

    setSelectedOptions(name: string, values: string[]) {
        logger.info("setSelectedOptions values=", values)
        this.setState({selectedOptions: values}, () => this.props.passSelectedInputOptions(values))
    }

    componentDidMount() {
        this.setAllUniqueOptions()
    }

    componentDidUpdate(prevProps: Readonly<MultiSelectProps>, prevState: Readonly<MultiSelectState>, snapshot?: any) {
        if (prevProps.allInputOptions !== this.props.allInputOptions) {
            this.setAllUniqueOptions()
        }
        if (prevProps.refreshId !== this.props.refreshId) {
            this.setState({
                selectedOptions: []
            })
        }
    }

    render() {
        return <FormikReactSelect
                customStyle={{
                    control: (base: any) => ({
                        ...base,
                        fontSize: "0.9rem"
                    })
                }}
                placeholder={this.props.title}
                name={"options"}
                id={"multi-select-table"}
                isMulti={true}
                passSelectedValuesAfterUserDoneSelecting={this.setSelectedOptions.bind(this)}
                value={this.state.selectedOptions}
                options={this.state.allOptions.map(option => (
                    {
                        label: option,
                        value: option
                    }))}
            />
    }
}