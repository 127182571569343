import {JobStatus, Normalize} from "./Models";

export interface AggregatedVisualizationPutApiRequest {
    dotmaticsPlates: string[]
    cellLines: string[]
    knockouts: string[]
    drugs: string[]
    targets: string[]
    normalizeDMSO: Normalize
    singleCurvePerPlot: boolean
    summarizeBiologicalReplicates: boolean
    onlyCountersignedPlate: boolean
    confidenceInterval: boolean
}

export function convertToMap(req: AggregatedVisualizationPutApiRequest): Record<string, string> {
    return {
        "dotmaticsPlates": req.dotmaticsPlates.join(","),
        "cellLines": req.cellLines.join(","),
        "knockouts": req.knockouts.join(","),
        "drugs": req.drugs.join(","),
        "targets": req.targets.join(","),
        "normalizeDMSO": req.normalizeDMSO.toString(),
        "singleCurvePerPlot": req.singleCurvePerPlot.toString(),
        "summarizeBiologicalReplicates": req.summarizeBiologicalReplicates.toString(),
        "onlyCountersignedPlate": req.onlyCountersignedPlate.toString(),
    }
}

export interface AggregatedVisualizationPutApiResponse {
    status?: JobStatus
    message?: string
    data?: AggregatedVisualizationResultRow[]
}

export interface AggregatedVisualizationResultRow {
    id: string
    jobId: string
    dotmaticsPlateId: string
    noPlates: number
    cellLine: string
    knockout: string
    drug: string
    drugDilution: string
    drugTarget: string
    ic50Um: number
    ec50Um: number
    ec50Viability: number
    hillSlope: number
    minViability: number
    drugSensitivityScore3: number
    ic20Um: number
    ic80Um: number
    areaUnderCurvePercentage: number
    dataQualityOk: boolean
    qcNote: string
    s3Key: string
    s3GeneratedDownloadUrl?: string
}

// TODO - 2. Make nav bar clearer what you click on