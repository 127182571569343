import React from "react";
import {SearchBoxComparator} from "../../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "../../Common/SearchResultTableWithUpdateUI";
import {View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {GenGenPlateTableDataApi} from "../GenGenPlateTableDataApi";
import {GenGenPlateMetadataForm} from "./GenGenPlateMetadataForm";

interface GenGenPlateMetadataSearchResultTableState {}

interface GenGenPlateMetadataSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    enableFilter: boolean
    view: View
}

export class GenGenPlateMetadataSearchResultTable extends React.Component<GenGenPlateMetadataSearchResultTableProps, GenGenPlateMetadataSearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
        enableFilter: true,
    }

    render() {
        return <SearchResultTableWithUpdateUI
                refreshToken={this.props.refreshToken}
                databaseName={genGenPlateApiConst.DATABASE_NAME}
                tableName={genGenPlateApiConst.tableName.PLATE_METADATA}
                keywords={this.props.keywords}
                columns={""}
                comparator={this.props.comparator}
                keyField={"plateId"}
                title={this.props.view === View.Update ? "" : "Gen Gen Plate Metadata"}
                legends={<></>}
                rowClasses={row => "table-light"}
                columnsHidden={["downloadLink"]}
                columnsDisplayInOrder={[
                    "plateId", "cellLine", "project", "geneAs", "geneBs", "replicateNumber",
                    "deliveryMethod", "owner", "experimentStartDate", "experimentDurationInDays", "dotmaticsExperimentId",
                    "lastModified", "additionalNote"
                ]}
                view={this.props.view}
                enableFilter={this.props.enableFilter}
                modalForm={GenGenPlateMetadataForm}
                searchTable={GenGenPlateTableDataApi.searchTable}
            />
    }
}