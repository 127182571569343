import React from "react";
import {Badge} from "react-bootstrap";
import {SearchBoxComparator} from "../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "../Common/SearchResultTableWithUpdateUI";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";
import {GppTableDataApi} from "./GppTableDataApi";
import {IndelForm} from "./IndelForm";

const INDEL_SCORE_UPPER_THRESHOLD = 80;
const INDEL_SCORE_LOWER_THRESHOLD = 40;

interface IndelSearchResultTableState {}

interface IndelSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    view: View
    legends: boolean
    enableFilter: boolean
}

export class IndelSearchResultTable extends React.Component<IndelSearchResultTableProps, IndelSearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
        legends: true,
        enableFilter: true
    }

    render() {
        return <SearchResultTableWithUpdateUI
            refreshToken={this.props.refreshToken}
            databaseName={gppApiConsts.DATABASE_NAME}
            tableName={"indel_performance"}
            keywords={this.props.keywords}
            columns={""}
            comparator={this.props.comparator}
            keyField={"id"}
            title={this.props.view === View.Update ?  "" : "Indel performance"}
            modalForm={IndelForm}
            legends={this.props.legends && <>
                <p><Badge bg={"success"} className={"me-2"}> </Badge>Indel percentage &gt;= {INDEL_SCORE_UPPER_THRESHOLD}</p>
                <p><Badge bg={"danger"} className={"me-2"}> </Badge>Indel percentage &lt; {INDEL_SCORE_LOWER_THRESHOLD}</p>
            </>}
            rowClasses={(row, index) => {
                if (this.props.legends) {
                    if (row.indelPercentage >= INDEL_SCORE_UPPER_THRESHOLD) {
                        return "table-success"
                    }
                    if (row.indelPercentage < INDEL_SCORE_LOWER_THRESHOLD) {
                        return "table-danger"
                    }
                }
                return "table-light"
            }}
            columnsHidden={[]}
            columnsDisplayInOrder={[
                "owner", "guideSequence", "gene", "plasmidId", "plateId", "cellLine", "indelPercentage", "knockoutScore", "modelFit",
                "cellSelectionMarker", "collectionTimepoint", "deliveryMethod", "method", "project", "id"
            ]}
            view={this.props.view}
            enableFilter={this.props.enableFilter}
            searchTable={GppTableDataApi.searchTable}
        />
    }
}