import React from "react";
import {SearchBoxComparator} from "../../../CommonFunc/Enum";
import {SearchResultTableWithUpdateUI} from "../../Common/SearchResultTableWithUpdateUI";
import {View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";
import {GenGenPlateTableDataApi} from "../GenGenPlateTableDataApi";
import {GenGenPlateMapForm} from "./GenGenPlateMapForm";

interface GenGenPlateMapSearchResultTableState {}

interface GenGenPlateMapSearchResultTableProps {
    keywords: string
    comparator: SearchBoxComparator
    refreshToken: string
    enableFilter: boolean
    view: View
}

export class GenGenPlateMapSearchResultTable extends React.Component<GenGenPlateMapSearchResultTableProps, GenGenPlateMapSearchResultTableState> {
    static defaultProps = {
        refreshToken: "",
        keywords: "",
        enableFilter: true,
    }

    render() {
        return <SearchResultTableWithUpdateUI
                refreshToken={this.props.refreshToken}
                databaseName={genGenPlateApiConst.DATABASE_NAME}
                tableName={genGenPlateApiConst.tableName.MAP}
                keywords={this.props.keywords}
                columns={""}
                comparator={this.props.comparator}
                keyField={"id"}
                title={this.props.view === View.Update ? "" : "Gen Gen Plate Map"}
                legends={<></>}
                rowClasses={row => "table-light"}
                columnsHidden={["plateMap", "lastModified", "downloadLink"]}
                columnsDisplayInOrder={["plateId", "geneAs", "geneBs", "project", "name", "owner"]}
                enableFilter={this.props.enableFilter}
                view={this.props.view}
                modalClassName={"modal-larger-width"}
                modalForm={GenGenPlateMapForm}
                searchTable={GenGenPlateTableDataApi.searchTable}
            />
    }
}