import React from "react";
import {Alert, Button, Col, Container, Image, Nav, Navbar, Row} from "react-bootstrap";
import {currentEnv} from "../Const";
import enginebio_icon from "../enginebio_icon.png";
import {SangerQC} from "./SangerQC/SangerQC";
import {getUsername} from "./Auth/LoginUser";
import {ExperimentalFeatures} from "./ExperimentalFeatures/ExperimentalFeatures";
import {SynergyFinder} from "./SynergyFinder/SynergyFinder";
import {DataCatalogs} from "./DataCatalog/DataCatalogs";
import {PharmGenAggregation} from "./PharmGenAggregation/PharmGenAggregation";
import {
    BarChartFill,
    Table,
    GraphUp,
    Power, Stack,
    UpcScan
} from "react-bootstrap-icons";
import "./BioToolsUi.css"

interface BioToolsNavBarState {
    currentView: string
}

interface BioToolsNavBarProps {
    signOut: () => any
    user: string
}

export class BioToolsUi extends React.Component<BioToolsNavBarProps, BioToolsNavBarState> {
    constructor(props: any) {
        console.log("BioToolsNavBar - constructor")
        super(props);
        this.state = {
            currentView: "data-catalogs"
        }
    }

    componentDidMount() {
        getUsername()
    }

    setView(currentView: string|null) {
        console.log("User set view = " + currentView)
        this.setState({
            currentView: currentView === null ? "data-catalogs" : currentView
        })
    }

    render() {
        return (
            <>
                <Row className={"bg-light"}>
                    <Col md={1} className={"pt-4 ps-5 border-bottom"}>
                        <Navbar.Brand  href="#">
                            <Image src={enginebio_icon} style={{ width: "2.5rem", height: "2.5rem"}}></Image>
                        </Navbar.Brand>
                    </Col>
                    {/*To avoid empty grey line between logo and nav bar border bottom*/}
                    <Col md={10} style={{marginLeft: "-1rem", marginRight: "-1rem"}}>
                        <Nav navbar={true} justify={true} fill={true} style={{width: "100%"}} variant={"tabs"} className="pt-4 ps-4 pe-4" defaultActiveKey={"data-catalogs"} onSelect={this.setView.bind(this)}>
                            <Nav.Item>
                                <Nav.Link active={this.state.currentView === "data-catalogs"} eventKey={"data-catalogs"}>
                                    <Table /> Data Catalog
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"pharm-gen-aggregation"}>
                                    <Stack/> Drug - Cell Line Viability
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"data-visualization"}>
                                    <BarChartFill/> Data Visualization
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"sanger-qc"}>
                                    <UpcScan/> Sanger QC
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"synergy-finder"}>
                                    <GraphUp /> Synergy Finder
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col md={1} className={"pt-4 ps-4 border-bottom"}>
                        <Button variant={"secondary"} className={"float-end"} onClick={this.props.signOut}><Power/></Button>
                    </Col>
                </Row>
                <Container className="p-4" style={{ maxWidth: "1600px" }} >
                    {currentEnv !== "production" && <Alert variant={"warning"}>You are using testing environment, please go to <a href={"https://enginebio.io"}>official environment</a> for day to day works.</Alert>}
                    {
                        {
                            "data-catalogs": <DataCatalogs />,
                            "data-visualization": <ExperimentalFeatures />,
                            "sanger-qc": <SangerQC />,
                            "synergy-finder": <SynergyFinder/>,
                            "pharm-gen-aggregation": <PharmGenAggregation />
                        }[this.state.currentView]
                    }
                </Container>
            </>
        );
    }
}