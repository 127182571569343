import {API, Logger} from "aws-amplify";
import {FormikHelpers, FormikValues} from "formik";
import React from "react";
import {Card} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import * as yup from "yup";
import {DataAudit} from "../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../CommonApi/Enum";
import {UsageTracking} from "../../CommonApi/UsageTracking";
import {ApiCallStatus} from "../../CommonFunc/Enum";
import {DataImportFormSubmissionStatus, HtmlForm, RowOfFormFields} from "../Common/HtmlForm";
import {View} from "../GenGenPlate/Enum";
import {gppApiConsts} from "./GppApiConsts";

const logger = new Logger("WesternBlotForm.tsx")

const TABLE_NAME: string = "western_blot"

export interface WesternBlot {
    id: string
    owner: string
    guideSequence: string
    guideDescription: string
    gene: string
    plateId: string
    cellLine: string
    cas9ExpressingCellLine: string
    cellSelectionMarker: string
    collectionTimepoint: string
    plasmidId: string
    deliveryMethod: string
    antibodyCompany: string,
    antibodyCatalogNumber: string
    antibodyDilution: string
    observedBandSize: string
    quantificationScore: string
    amountOfProteinLoadedInMicrogram: string
    normalisedAgainst: string
    comparedTo: string
    chemicalSubstrate: string
    exposureTimeInSecs: string
    pictureUploadUrlAntibody: string
    pictureUploadUrlLoadingControl: string
    additionalNote: string
}

interface WesternBlotFormState {
    rowsOfFormFields: RowOfFormFields[]
    schema: any
    initialValues: WesternBlot
    submissionStatus: DataImportFormSubmissionStatus
}

interface WesternBlotFormProps {
    displayHeader: boolean
    initialValues: WesternBlot
    autofillOwnerByLogInId: boolean
    view: View
}

export class WesternBlotForm extends React.Component<WesternBlotFormProps, WesternBlotFormState> {
    static defaultProps = {
        displayHeader: true,
        initialValues: null,
        autofillOwnerByLogInId: true,
    }

    constructor(props: WesternBlotFormProps) {
        super(props);
        this.state = {
            rowsOfFormFields: [
                {
                    colSize: 6,
                    fields: [
                        {name: "owner", dataType: "text", disable: true},
                        {name: "guideSequence", dataType: "text", disable: false},
                        {name: "guideDescription", dataType: "text", disable: false},
                        {name: "plateId", dataType: "text", disable: false},
                    ],
                },
                {
                    colSize: 3,
                    fields: [
                        {name: "gene", dataType: "text", disable: false},
                        {name: "cellLine", dataType: "text", disable: false},
                        {name: "cas9ExpressingCellLine", dataType: "select", disable: false},
                        {name: "cellSelectionMarker", dataType: "select", disable: false},
                        {name: "collectionTimepoint", dataType: "number", disable: false},
                        {name: "plasmidId", dataType: "text", disable: false},
                        {name: "deliveryMethod", dataType: "select", disable: false},
                        {name: "antibodyCatalogNumber", dataType: "text", disable: false},
                        {name: "antibodyCompany", dataType: "select", disable: false},
                        {name: "antibodyDilution", dataType: "text", disable: false},
                        {name: "normalisedAgainst", dataType: "select", disable: false},
                        {name: "comparedTo", dataType: "select", disable: false},
                        {name: "chemicalSubstrate", dataType: "select", disable: false},
                        {name: "exposureTimeInSecs", dataType: "number", disable: false},
                    ]
                },
                {
                    colSize: 6,
                    fields: [
                        {name: "observedBandSize", dataType: "text", disable: false},
                        {name: "quantificationScore", dataType: "select", disable: false},
                        {name: "amountOfProteinLoadedInMicrogram", dataType: "number", disable: false},
                        {name: "pictureUploadUrlAntibody", dataType: "text", disable: false},
                        {name: "pictureUploadUrlLoadingControl", dataType: "text", disable: false},
                    ],
                },
                {
                    colSize: 12,
                    fields: [
                        {name: "additionalNote", dataType: "text", disable: false}
                    ]
                }
            ],
            schema: yup.object({
                owner: yup.string().email().required(),
                // Make guide sequence a required field, since it much more like ppl forget to input it,
                // rather than guide sequence is not known, because vendor want to keep it secret.
                guideSequence: yup.string().required().matches(/^[acgtACGT]{20}(,[acgtACGT]{20})*$/, "Must only contains A, T, G or C, or comma if there are multiple sequences, and each sequence must be exact 20 letters in length."),
                gene: yup.string().required(),
                plateId: yup.string().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, "Must be an UUID format, example: 4628a038-aa77-4e44-89dd-b253d1dffd8c"),
                cellLine: yup.string().matches(/^[A-Za-z0-9]+$/, "Must only contains upper cases, lower cases and number, no space or special character").required(),
                cas9ExpressingCellLine: yup.string().required(),
                cellSelectionMarker: yup.string().required(),
                collectionTimepoint: yup.string(),
                plasmidId: yup.string().matches(/^PL[0-9]+/, "Must start with PL and then a number. For example: PL1252"),
                deliveryMethod: yup.string().required(),
                antibodyCompany: yup.string().required(),
                antibodyCatalogNumber: yup.string().required(),
                antibodyDilution: yup.string().required().matches(/^1:[0-9]+$/, "Must be 1:any_number. For example: 1:1000"),
                observedBandSize: yup.string().required().matches(/^[0-9.,]+$/, "Must be numbers, may put comma and no space in between for multiple values. E.g.: 1.23,6.45"),
                quantificationScore: yup.string().required(),
                amountOfProteinLoadedInMicrogram: yup.number(),
                normalisedAgainst: yup.string().required(),
                comparedTo: yup.string().required(),
                chemicalSubstrate: yup.string(),
                exposureTimeInSecs: yup.number(),
                pictureUploadUrlAntibody: yup.string().url().required(),
                pictureUploadUrlLoadingControl: yup.string().url().required(),
                additionalNote: yup.string(),
            }),
            initialValues: props.initialValues ? props.initialValues : {
                id: "", // Will be generated during submission
                owner: "",
                guideSequence: "",
                guideDescription: "",
                gene: "",
                plateId: "",
                cellLine: "",
                cas9ExpressingCellLine: "",
                cellSelectionMarker: "",
                collectionTimepoint: "",
                plasmidId: "",
                deliveryMethod: "",
                antibodyCompany: "",
                antibodyCatalogNumber: "",
                antibodyDilution: "",
                observedBandSize: "",
                quantificationScore: "",
                amountOfProteinLoadedInMicrogram: "",
                normalisedAgainst: "",
                comparedTo: "",
                chemicalSubstrate: "",
                exposureTimeInSecs: "",
                pictureUploadUrlAntibody: "",
                pictureUploadUrlLoadingControl: "",
                additionalNote: "",
            },
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }
    }

    onSubmit(values: any, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
        formikHelpers.setSubmitting(true)
        this.setState({
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            values["guideSequence"] = values["guideSequence"].toUpperCase()

            // We had a bug that second submit result in Error 1062: Duplicate entry '47f4e8cd-90f2-472e-b74a-10305619df01' for key 'PRIMARY'
            // To fix this we generate new UUID everytime submit button is triggered via PUT (but not PATCH).
            if (this.props.view === View.Import) {
                values["id"] = uuidv4()
            }

            if (values["antibodyCompany"].toLowerCase().includes("others") && !values["comments"].toLowerCase().includes("antibody company: ")) {
                formikHelpers.setFieldError("comments", "Please specify antibody company with \"Antibody company: xxxx\" inside comments.")
                formikHelpers.setSubmitting(false)
                return
            }

            if (values["deliveryMethod"].toLowerCase() === "lentivirus") {
                if (!values["plasmidId"]) {
                    formikHelpers.setFieldError("plasmidId", "plateId is required if delivery method is lentivirus.")
                    formikHelpers.setSubmitting(false)
                    return
                }
                if (!values["plateId"]) {
                    formikHelpers.setFieldError("plateId", "Plate ID is required if delivery method is lentivirus.")
                    formikHelpers.setSubmitting(false)
                    return
                }
            } else {
                if (values["plateId"]) {
                    formikHelpers.setFieldError("plateId", "Non-lentivirus plate shouldn't be on enginebio.io. Upload it to Dotmatics instead.")
                    formikHelpers.setSubmitting(false)
                    return
                }
            }

            logger.info("values = ", values)

            if (this.props.view === View.Import) {
                this.apiPut(values, formikHelpers)
            } else if (this.props.view === View.Update) {
                this.apiPatch(values, formikHelpers)
            }
        })
    }

    apiPut(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Import,
            table: TABLE_NAME,
            database: gppApiConsts.DATABASE_NAME,
            keywords: "", // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.put(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                body: {
                    table: TABLE_NAME,
                    data: values,
                },
            }).then(response => {
            logger.debug("Response from API", response)
            DataAudit.recordEvent({
                database: gppApiConsts.DATABASE_NAME,
                table: TABLE_NAME,
                rowId: values["id"],
                updateNote: "",
                dataObject: values,
            })
            this.setState({
                submissionStatus: {
                    status: ApiCallStatus.Completed,
                    message: "Western blot submitted successfully"
                }
            })
        }).catch(err => {
            logger.error(err)
            if (err.response) {
                if (err.response.status >= 500 || err.response.status < 400) {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: "Something wrong, please try again"
                        }
                    })
                } else {
                    this.setState({
                        submissionStatus: {
                            status: ApiCallStatus.Error,
                            message: err.response.data.message
                        }
                    })
                }
            }
        }).finally(() => {
            formikHelpers.setSubmitting(false)
        })
    }

    apiPatch(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            module: FrontendModule.DataCatalog,
            event: EventType.Update,
            table: TABLE_NAME,
            database: gppApiConsts.DATABASE_NAME,
            keywords: values["updateNotes"], // Comma seperated keywords is splited to seperate Cloudwatch log at backend.
        })
        API.patch(
            gppApiConsts.API_NAME,
            gppApiConsts.tableData.path.TABLE_DATA,
            {
                body: {
                    table: "western_blot",
                    data: values,
                },
            }).then(response => {
                DataAudit.recordEvent({
                    database: gppApiConsts.DATABASE_NAME,
                    table: TABLE_NAME,
                    rowId: values["id"],
                    updateNote: values["updateNotes"],
                    dataObject: values,
                })
                logger.debug("Response from API", response)
                this.setState({
                    submissionStatus: {
                        status: ApiCallStatus.Completed,
                        message: "Updated successfully! Record id is " + values["id"]
                    }
                })
            }).catch(err => {
                logger.error(err)
                if (err.response) {
                    if (err.response.status >= 500 || err.response.status < 400) {
                        this.setState({
                            submissionStatus: {
                                status: ApiCallStatus.Error,
                                message: "Something wrong, please try again"
                            }
                        })
                    } else {
                        this.setState({
                            submissionStatus: {
                                status: ApiCallStatus.Error,
                                message: err.response.data.message
                            }
                        })
                    }
                }
            }).finally(() => {
                formikHelpers.setSubmitting(false)
            })
    }

    render() {
        return <Card bg={"light"}>
            {this.props.displayHeader && <Card.Header><b>Add new Western blot</b></Card.Header>}
            <Card.Body>
                <HtmlForm
                    databaseName={"gpp"}
                    tableName={"western_blot"}
                    initialValues={this.state.initialValues}
                    schema={this.state.schema}
                    rowsOfFormFields={this.state.rowsOfFormFields}
                    onSubmit={this.onSubmit.bind(this)}
                    submissionStatus={this.state.submissionStatus}
                    autofillOwnerByLogInId={this.props.autofillOwnerByLogInId}
                    view={this.props.view}
                />
            </Card.Body>
        </Card>
    }
}