// TODO - change it to a different bucket once you confirmed how to use multiple bucket here.
import {shortenEnv} from "../../Const";

export const BUCKET_NAME = `${shortenEnv}-pharm-gen-aggregation-output-graph-image` // TODO - change bucket

export const pharmGenAggregationApiConsts = {
    API_NAME: `${shortenEnv}-pharm-gen-aggregation`,
    API_ENDPOINT: `https://${shortenEnv}-pharm-gen-aggregation.enginebio.io`,
    API_PATH: {
        AGGREGATED_VISUALIZATION: "/aggregated-visualization",
        MENU_OPTIONS: "/menu-options"
    },
}