import {API, Logger} from "aws-amplify";
import {FormikValues} from "formik";
import {FormikHelpers} from "formik/dist/types";
import moment from "moment";
import React from "react";
import {Card} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";
import * as yup from "yup";
import {DataAudit} from "../../../CommonApi/DataAudit";
import {EventType, FrontendModule} from "../../../CommonApi/Enum";
import {UsageTracking} from "../../../CommonApi/UsageTracking";
import {ApiCallStatus} from "../../../CommonFunc/Enum";
import {DataImportFormSubmissionStatus, HtmlForm, RowOfFormFields} from "../../Common/HtmlForm";
import {DeliveryMethod, View} from "../Enum";
import {genGenPlateApiConst} from "../GenGenPlateApiConst";

const logger = new Logger("GenGenPlateMetadataForm.tsx")

export interface GenGenPlateMetaData {
    dotmaticsExperimentId: string
    replicateNumber: number
    plateId: string,
    cellLine: string,
    experimentStartDate: string,
    project: string,
    experimentDurationInDays: number,
    geneAs: string,
    geneBs: string,
    owner: string,
    additionalNote: string
    deliveryMethod: DeliveryMethod
    downloadLink: string
    // TODO - check if Dotmatics has URL generation point to each Dotmatics experiment.
    lastModified: string
}

interface GenGenPlateFormProps {
    autofillOwnerByLogInId: boolean
    initialValues: GenGenPlateMetaData
    displayHeader: boolean
    view: View
}

interface GenGenPlateFormState {
    rowsOfFormFields: RowOfFormFields[]
    schema: any
    initialValues: GenGenPlateMetaData
    submissionStatus: DataImportFormSubmissionStatus
}

export class GenGenPlateMetadataForm extends React.Component<GenGenPlateFormProps, GenGenPlateFormState> {
    static defaultProps = {
        autofillOwnerByLogInId: true,
        displayHeader: true,
        initialValues: null,
    }

    constructor(props: any) {
        // Constructor get call everytime we press update button from TableDataAPIWithUpdateModal
        super(props);

        let initialValuesFromProps = props.initialValues
        if (initialValuesFromProps) {
            initialValuesFromProps["experimentStartDate"] = moment(new Date(initialValuesFromProps["experimentStartDate"])).format("YYYY-MM-DD")
        }

        this.state = {
            rowsOfFormFields: [
                {
                    colSize: 3,
                    fields: [
                        // Require experiment ID since it'll force user to create experiment in Dotmatics first.
                        // And it will help us link it with indel, western-blot data.
                        {name: "dotmaticsExperimentId", dataType: "text", disable: false},
                        {name: "replicateNumber", dataType: "number", disable: false},
                        {name: "cellLine", dataType: "text", disable: false},
                        {name: "experimentStartDate", dataType: "date", disable: false},
                        {name: "project", dataType: "text", disable: false},
                        {name: "experimentDurationInDays", dataType: "number", disable: false},
                    ]
                },
                {
                    colSize: 6,
                    fields: [
                        {name: "owner", dataType: "text", disable: true},
                        {name: "geneAs", dataType: "text", disable: false, placeholder: "FANCM,BLM"},
                        {name: "geneBs", dataType: "text", disable: false, placeholder: "TEAD1,PP2R2A,PKMYT1"},
                        {name: "deliveryMethod", dataType: "select", disable: false}
                    ]
                },
                {
                    colSize: 12,
                    fields: [
                        {name: "additionalNote", dataType: "text", disable: false},
                    ]
                },
            ],
            schema: yup.object({
                // TODO after prototype - feasible to check if experiment ID is valid (even better refers to a non-deleted / open experiment)?
                dotmaticsExperimentId: yup.string().required(),
                replicateNumber: yup.number().moreThan(0).required(),
                // Use increment number > UUID --> UUID is difficult to remember, and users need to fill it in to plate map and plate raw data form.
                plateId: yup.string().required(),
                cellLine: yup.string().required(),
                owner: yup.string().email().required(),
                experimentStartDate: yup.date().required(),
                project: yup.string().required(),
                experimentDurationInDays: yup.number().required(),
                additionalNote: yup.string(),
                geneAs: yup.string().matches(/^[A-Za-z0-9-, ]+$/, "Must contains only gene name, comma and space").required("Required field, multiple genes with comma seperated. For example: FANCM, TEAD1"),
                geneBs: yup.string().matches(/^[A-Za-z0-9-, ]+$/, "Must contains only gene name, comma and space").required("Required field, multiple genes with comma seperated. For example: FANCM, TEAD1"),
                deliveryMethod: yup.string().required(),
            }),
            initialValues: initialValuesFromProps === null ? {
                dotmaticsExperimentId: "",
                replicateNumber: 1,
                plateId: uuidv4(),
                cellLine: "",
                owner: "",
                experimentStartDate: "",
                project: "",
                experimentDurationInDays: "",
                additionalNote: "",
                geneAs: "",
                geneBs: "",
                deliveryMethod: "",
            } : initialValuesFromProps,
            submissionStatus: {
                status: ApiCallStatus.NoData,
                message: ""
            }
        }
    }

    componentDidMount() {

    }

    onSubmit(values: any, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
        this.setState({
            submissionStatus: {
                status: ApiCallStatus.Loading,
                message: ""
            }
        }, () => {
            // Any custom validation should be put here.
            if (this.props.view === View.Import) {
                values["plateId"] = uuidv4()
            }
            logger.info("Formik values before submission ", values)

            if (this.props.view === View.Import) {
                this.apiPut(values, formikHelpers)
            } else if (this.props.view === View.Update) {
                this.apiPatch(values, formikHelpers)
            }
        })
    }

    apiPut(values: GenGenPlateMetaData, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            uuid: uuidv4(),
            module: FrontendModule.DataCatalog,
            event: EventType.Import,
            table: genGenPlateApiConst.tableName.PLATE_METADATA,
            database: genGenPlateApiConst.DATABASE_NAME,
            keywords: "", // Comma seperated
            timestamp: new Date()
        })
        formikHelpers.setSubmitting(true)
        API.put(
            genGenPlateApiConst.API_NAME,
            genGenPlateApiConst.path.PLATE_METADATA,
            {
                body: values,
            }).then(response => {
                DataAudit.recordEvent({
                    database: genGenPlateApiConst.DATABASE_NAME,
                    table: genGenPlateApiConst.tableName.PLATE_METADATA,
                    rowId: values.plateId,
                    updateNote: "",
                    dataObject: values
                })
                logger.debug("Response from API", response)

                // Set success message
                this.setState({
                    submissionStatus: {
                        status: ApiCallStatus.Completed,
                        message: `Submitted successful! Plate ID = ${values.plateId}`,
                    },
                })
                formikHelpers.resetForm()
            }).catch(err => {
                logger.error(err)
                if (err.response) {
                    if (err.response.status >= 500 || err.response.status < 400) {
                        this.setState({
                            submissionStatus: {
                                status: ApiCallStatus.Error,
                                message: "Something wrong, please try again"
                            }
                        })
                    } else {
                        this.setState({
                            submissionStatus: {
                                status: ApiCallStatus.Error,
                                message: err.response.data.message,
                            }
                        })
                    }
                }
            }).finally(() => {
                formikHelpers.setSubmitting(false)
            })
    }

    apiPatch(values: any, formikHelpers: FormikHelpers<FormikValues>) {
        UsageTracking.recordEvent({
            uuid: uuidv4(),
            module: FrontendModule.DataCatalog,
            event: EventType.Update,
            table: genGenPlateApiConst.tableName.PLATE_METADATA,
            database: genGenPlateApiConst.DATABASE_NAME,
            keywords: "", // Comma seperated
            timestamp: new Date()
        })
        formikHelpers.setSubmitting(true)
        API.patch(
            genGenPlateApiConst.API_NAME,
            genGenPlateApiConst.path.PLATE_METADATA,
            {
                body: values,
            }).then(response => {
                DataAudit.recordEvent({
                    database: genGenPlateApiConst.DATABASE_NAME,
                    table: genGenPlateApiConst.tableName.PLATE_METADATA,
                    rowId: values.plateId,
                    updateNote: values.updateNote,
                    dataObject: values
                })

                logger.debug("Response from API", response)
                // Set success message
                this.setState({
                    submissionStatus: {
                        status: ApiCallStatus.Completed,
                        message: `Updated successful! Plate ID = ${values.plateId}`,
                    },
                    initialValues: values
                })
            }).catch(err => {
                logger.error(err)
                if (err.response) {
                    if (err.response.status >= 500 || err.response.status < 400) {
                        this.setState({
                            submissionStatus: {
                                status: ApiCallStatus.Error,
                                message: "Something wrong, please try again"
                            }
                        })
                    } else {
                        this.setState({
                            submissionStatus: {
                                status: ApiCallStatus.Error,
                                message: err.response.data.message
                            }
                        })
                    }
                }
            }).finally(() => {
                formikHelpers.setSubmitting(false)
            })
    }


    render() {
        let htmlForm: JSX.Element = <HtmlForm
            databaseName={genGenPlateApiConst.DATABASE_NAME}
            tableName={genGenPlateApiConst.tableName.PLATE_METADATA}
            initialValues={this.state.initialValues}
            schema={this.state.schema}
            rowsOfFormFields={this.state.rowsOfFormFields}
            onSubmit={this.onSubmit.bind(this)}
            submissionStatus={this.state.submissionStatus}
            autofillOwnerByLogInId={this.props.autofillOwnerByLogInId}
            view={this.props.view}
        />

        if (this.props.view === View.Read) {
            // In Read view, this form is always displayed side by side, in the UpdateModal, with other forms.
            return <>{htmlForm}</>
        }

        return <Card bg={"light"}>
            {this.props.displayHeader && <Card.Header><b>Add gen-gen plate metadata</b></Card.Header>}
            <Card.Body>{htmlForm}</Card.Body>
        </Card>
    }
}